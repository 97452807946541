<template>
    <div>
        <Navbar title="Crear Empresa"></Navbar>
        <div class="mt-4">
            <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 mx-4">
                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">
                    <div class="lg:col-span-2 mx-20">
                        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Razón social</label>
                                </div>
                                <input v-model="company.company_name" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Nombre Comercial</label>
                                </div>
                                <input v-model="company.commercial_name" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Tipo de Identificación</label>
                                </div>
                                <div class="relative">
                                    <select v-model="company.identification_type"
                                        class="block mt-1 w-full appearance-none bg-gray-50 border border-gray-300 p-1 rounded leading-tight focus:outline-none focus:border-blue-500">
                                        <option value="PLACA">Placa</option>
                                        <option value="NIT">Nit</option>
                                        <option value="RUC">Ruc</option>
                                        <option value="IDENTIFICACION DEL EXTERIOR">Identificación del Exterior</option>
                                        <option value="CEDULA">Cédula</option>
                                        <option value="PASAPORTE">Pasaporte</option>
                                    </select>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center p-1 text-gray-700">
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Identificación</label>
                                </div>
                                <input v-model="company.identification" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Tipo de Contacto</label>
                                </div>
                                <div class="relative">
                                    <select v-model="company.contact_type"
                                        class="block mt-1 w-full appearance-none bg-gray-50 border border-gray-300 p-1 rounded leading-tight focus:outline-none focus:border-blue-500">
                                        <option value="PROVEEDOR">Proveedor</option>
                                        <option value="CLIENTE">Cliente</option>
                                    </select>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center p-1 text-gray-700">
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="md:col-span-5"></div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Tipo de Contribuyente</label>
                                </div>
                                <div class="relative">
                                    <select v-model="company.taxpayer_type"
                                        class="block mt-1 w-full appearance-none bg-gray-50 border border-gray-300 p-1 rounded leading-tight focus:outline-none focus:border-blue-500">
                                        <option value="PERSONA NATURAL">Persona natural</option>
                                        <option value="SOCIEDAD">Sociedad</option>
                                    </select>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center p-1 text-gray-700">
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="md:col-span-5"></div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Dirección</label>
                                </div>
                                <input v-model="company.address" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5"></div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Teléfono convencional</label>
                                </div>
                                <input v-model="company.regular_phone" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Teléfono celular</label>
                                </div>
                                <input v-model="company.cell_phone" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Correo</label>
                                </div>
                                <input v-model="company.email" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                        </div>
                    </div>
                    <div class="lg:col-span-2 mx-20">
                        <div class="flex mt-3 items-center justify-start space-x-4">
                            <button @click="goBack"
                                class="bg-white ring-1 ring-inset ring-gray-300 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                Atrás
                            </button>
                            <button @click="createCompany()" :disabled="loadingSave || !isValid"
                                :class="{ 'opacity-50 cursor-not-allowed': loadingSave || !isValid }"
                                class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                <span v-if="loadingSave" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import { mapGetters } from 'vuex';

export default {
    components: {
        Navbar
    },
    data() {
        return {
            userId: 0,
            company: {
                company_name: '',
                commercial_name: '',
                identification_type: '',
                identification: '',
                contact_type: '',
                taxpayer_type: '',
                address: '',
                regular_phone: '',
                cell_phone: '',
                email: ''
            },

            showEmailError: false,
            loadingSave: false,
        }
    },
    mounted() {
    },
    methods: {
        createCompany() {
            this.loadingSave = true;
            let url = `${this.getApiUrl}/company`;
            let token = this.getUserData.token;
            let body = JSON.stringify(this.company);

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response =>response.json())
                .then(data => {
                    let companyId = data.id;
                    if (data.status == 201) {
                        setTimeout(() => {
                            this.showMsg('success', 'Crear Empresa', 'Empresa creada satisfactoriamente');
                            this.loadingSave = false;
                            this.$router.push({ name: 'EditCompany', params: { companyId } });
                        }, 300)
                    } else if (data.message == 'Company already exist') {
                        setTimeout(() => {
                            this.loadingSave = false;
                            this.showMsg('error', 'Crear Empresa', 'La empresa ya existe');
                        }, 300)
                    }
                })
                .catch(error => {
                    this.loadingSave = false;
                    this.showMsg('error', 'Crear Empresa', 'Error al crear la empresa');
                });
        },
        goBack() {
            this.$router.push({ name: 'CompanyList' });
        },
        isValidEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(this.company.email);
        },
        isValidPhone() {
            const phoneNumberPattern = /^0\d{9}$/;
            return phoneNumberPattern.test(this.company.cell_phone);
        },
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
        isValid() {
            let validations = [
                this.company.commercial_name.length != 0,
                this.company.company_name.length != 0,
                this.company.identification_type.length != 0,
                this.company.identification.length != 0,
                this.company.contact_type.length != 0,
                this.company.address.length != 0,
                this.isValidEmail(),
                this.isValidPhone()
            ]

            return validations.every(validation => validation);
        }
    }
}
</script>