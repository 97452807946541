<template>
    <section class="vh-100">
        <div class="container-fluid h-custom">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="../../assets/logo.png" class="img-fluid" alt="Sample image">
                </div>
                <div class="card box col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <div class="card-body">
                        <div v-if="loginFailure" class="alert alert-danger">
                            <p class="message">Credenciales incorrectas</p>
                        </div>
                        <div>
                            <label class="main-label my-4">Inicia sesión en Inedyc</label>
                        </div>
                        <div>
                            <div class="form-outline mb-4">
                                <label class="form-label">Correo electrónico</label>
                                <input v-model="email" type="email" class="form-control"
                                    placeholder="Ingrese su email" />
                            </div>

                            <div class="form-group mb-3">
                                <label>Contraseña</label>
                                <input v-model="password" type="password" class="form-control"
                                placeholder="Ingrese su contraseña" />
                            </div>

                            <div class="text-lg my-4 pt-2">
                                <button class="btn-new py-1 px-4 text-white" @click="login()" :disabled="loadingLogin">
                                    <span v-if="loadingLogin" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Entrar
                                </button>
                            </div>

                            <div class="align-items-center mb-4">
                                <a href="/auth/forgot-password" class="link-primary">¿Has olvidado la contraseña?</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'SignIn',
    data() {
        return {
            email: '',
            password: '',

            loadingLogin: false,
            loginFailure: false
        }
    },
    methods: {
        ...mapActions(['saveUserData']),

        login() {
            this.loadingLogin = true;
            let url = `${this.getApiUrl}/users/login`;
            let body = {
                email: this.email,
                password: this.password
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => {
					return response.json();
				})
                .then(data => {
                    if (data.status != 200) {
                        this.loginFailure = true;
                    } else {
                        this.loginFailure = false;
                        this.saveUserData(data.payload);
                        this.saveLocalUserData(data);

                        let redirect;
                        if (data.payload.role != 'superadmin') {
                            redirect = '/panel/report-list'
                        }
                        else {
                            redirect = this.$route.query.redirect || '/panel';
                        }
                        
                        this.$router.push(redirect);
                    }
                    this.loadingLogin = false;
                });
        },
        saveLocalUserData(data) {
            localStorage.setItem('token', data.payload.token);
            localStorage.setItem('userEmail', data.payload.email);
            localStorage.setItem('name', data.payload.name);
            localStorage.setItem('last_name', data.payload.last_name);
            localStorage.setItem('role', data.payload.role);
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    }
}
</script>

<style scoped>
.main-label {
    font-size: 1.5rem;
    font-weight: 600
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.h-custom {
    height: calc(100% - 73px);
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}

.btn-new {
    font-family: 'Nunito Sans';
    background-color: #FFA500;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(50,50,93,0.1), 0 2px 3px 0 rgba(50,50,93,0.1), 0 2px 3px 0 rgba(50,50,93,0.08), 0 1px 1.5px 0 rgba(0,0,0,0.08), 0 1px 2px 0 rgba(0,0,0,0.06);;
}

.box:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-45deg, #c6f8ff 0%, #595cff 100% );
    transform: translate3d(0px, 20px, 0) scale(0.95);
    filter: blur(20px);
    opacity: var(0.7);
    transition: opacity 0.3s;
    border-radius: inherit;
}

.box::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
}

.tawk-min-container {
    display: none !important;
}
</style>
  