import { createRouter, createWebHistory } from 'vue-router';
import SignIn from '../modules/auth/SignIn.vue';
import ResetPassword from '../modules/auth/ResetPassword.vue';
import ForgotPassword from '../modules/auth/ForgotPassword.vue';
import Dashboard from '../modules/dashboard/Dashboard.vue';
import UsersList from '../modules/users/UsersList.vue';
import EditUser from '../modules/users/EditUser.vue';
import CreateUser from '@/modules/users/CreateUser.vue';
import Contractor from '../modules/contractor/Contractor.vue';
import CreateReport from '@/modules/reports/CreateReport.vue';
import EditReport from '@/modules/reports/EditReport.vue';
import ReportList from '@/modules/reports/ReportList.vue';
import PersonalProfile from '@/modules/users/PersonalProfile.vue';
import CompanyList from '@/modules/company/CompanyList.vue';
import CreateCompany from '@/modules/company/CreateCompany.vue';
import EditCompany from '@/modules/company/EditCompany.vue';
import Settings from '@/modules/settings/Settings.vue';
import Panel from '@/modules/panel/Panel.vue';
import store from '../store';

const showWidget = () => {
  window.Tawk_API.showWidget();
}

const hideWidget = () => {
  window.Tawk_API.hideWidget();
}

const tawkWidgetToggle = (show) => {

  if (window.$_Tawk && window.$_Tawk.init) {
    show ? showWidget() : hideWidget();
  } else {
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = function () {
        show ? showWidget() : hideWidget();
      };
    }

  }

}

const authGuard = (to, from, next) => {
  const localStorageToken = localStorage.getItem('token');

  if (localStorageToken) {
    fetch(`${store.getters.getApiUrl}/users/verify-token`, {
      method: 'POST',
      headers: {
        'Authorization': localStorageToken
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error de respuesta del servidor');
        }
      })
      .then(data => {
        if (data.status === 200) {
          store.commit('setUserData', {
            token: localStorageToken,
            role: data.payload.role,
            email: data.payload.email,
            name: data.payload.name,
            last_name: data.payload.last_name,
            avatar: data.payload.avatar
          });

          localStorage.setItem('userEmail', data.payload.email);
          localStorage.setItem('name', data.payload.name);
          localStorage.setItem('last_name', data.payload.last_name);
          localStorage.setItem('role', data.payload.role);

          if (data.payload.role != 'customer') {
            tawkWidgetToggle(false);
          } else {
            tawkWidgetToggle(true);
          }

          next();
        } else {
          next({
            path: '/auth/sign-in',
            query: { redirect: to.fullPath }
          });
        }
      })
      .catch(error => {
        console.error('Error al verificar el token:', error);
        next({
          path: '/auth/sign-in',
          query: { redirect: to.fullPath }
        });
      });
  } else {
    next({
      path: '/auth/sign-in',
      query: { redirect: to.fullPath }
    });
  }
};


const routes = [
  { path: '/', redirect: '/auth/sign-in' },
  {
    path: '/panel',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Panel
      },
      {
        path: 'users-list',
        name: 'UsersList',
        component: UsersList
      },
      {
        path: 'edit-user/:userId',
        name: 'EditUser',
        component: EditUser
      },
      {
        path: 'personal-profile',
        name: 'PersonalProfile',
        component: PersonalProfile
      },
      {
        path: 'create-user',
        name: 'CreateUser',
        component: CreateUser
      },
      {
        path: 'contractor',
        name: 'ContractorData',
        component: Contractor
      },
      {
        path: 'report-list',
        name: 'ReportList',
        component: ReportList
      },
      {
        path: 'reports-new',
        name: 'CreateReport',
        component: CreateReport
      },
      {
        path: 'edit-report/:reportId',
        name: 'EditReport',
        component: EditReport
      },
      {
        path: 'company-list',
        name: 'CompanyList',
        component: CompanyList
      },
      {
        path: 'create-company',
        name: 'CreateCompany',
        component: CreateCompany
      },
      {
        path: 'edit-company/:companyId',
        name: 'EditCompany',
        component: EditCompany
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings
      }
    ]
  },
  {
    path: '/auth',
    children: [
      {
        path: 'sign-in',
        name: 'SignIn',
        component: SignIn
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        path: 'reset-password/:token',
        name: 'ResetPassword',
        component: ResetPassword
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    authGuard(to, from, next);
  } else {
    tawkWidgetToggle(false);
    next();
  }
});



export default router
