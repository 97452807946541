<template>
    <div class="lg:col-span-2">
        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Fecha</label>
                </div>
                <VDatePicker v-model="currentData.date" :popover="false">
                    <template #default="{ togglePopover, inputValue, inputEvents }">
                        <div class="flex rounded-lg border border-gray-300 dark:border-gray-600 overflow-hidden">
                            <button
                                class="flex justify-center items-center px-2 bg-accent-100 hover:bg-accent-200 text-accent-700 border-r border-gray-300 dark:bg-gray-700 dark:text-accent-300 dark:border-gray-600 dark:hover:bg-gray-600"
                                @click="() => togglePopover()">
                                <i class="far fa-calendar"></i>
                            </button>
                            <input :value="inputValue" v-on="inputEvents" @click="() => togglePopover()"
                                class="flex-grow px-2 py-1 w-full bg-gray-50 text-center" />
                        </div>
                    </template>
                </VDatePicker>
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Aceite</label>
                </div>
                <input v-model="currentData.oil" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Duración</label>
                </div>
                <input v-model="currentData.duration" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Ciclos de Circulación</label>
                </div>
                <input v-model="currentData.circulationCycles" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
            <div class="md:col-span-4">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Tratamiento</label>
                </div>
            </div>
            <div class="md:col-span-4">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Temperatura</label>
                </div>
            </div>
            <div class="md:col-span-4">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Contenido Agua</label>
                </div>
            </div>
        </div>

        <div class="max-h-[13rem] overflow-y-auto">
            <div v-for="value in currentData.rows"
                class="grid gap-x-5 gap-y-1 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-1">
                <div class="md:col-span-4">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.treatment" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-4">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.temperature" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-4">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.waterContent" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex mt-3 items-center justify-end space-x-4">
            <button @click="addRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-plus"></i>
            </button>
            <button @click="removeRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-minus"></i>
            </button>
        </div>

        <div class="my-2">
            <label class="text-base font-semibold leading-7 text-gray-900 block text-left">Descripción:</label>
            <div class="flex items-center justify-center">
                <input v-model="currentData.description" type="text" class="border mt-1 rounded p-2 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="border-b border-gray-300 mb-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Termofiltrado de Aceite Dieléctrico',
            currentData: {
                date: '',
                oil: '',
                duration: '',
                circulationCycles: '',
                rows: [{
                    treatment: '',
                    temperature: '',
                    waterContent: '',
                }],
                description: ''
            }
        }
    },
    mounted() {
        if (Object.entries(this.data).length > 0) {
            this.currentData = this.data;
        } else {
            this.currentData = {
                date: '',
                oil: '',
                duration: '',
                circulationCycles: '',
                rows: [{
                    treatment: '',
                    temperature: '',
                    waterContent: '',
                }],
                description: ''
            }
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
        addRow() {
            this.currentData.rows.push({
                treatment: '',
                temperature: '',
                waterContent: '',
            })
        },
        removeRow() {
            this.currentData.rows.pop()
        }
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                let date = moment(newValue.date).format('DD/MM/YYYY');
                newValue.date = date;
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>