import { ref, computed } from 'vue'

export const collapsed = ref(true)
export const toggleSidebar = (state = null) => {
  if (state) {
    collapsed.value = state;
  }
  else {
    collapsed.value = !collapsed.value
  }
}
export const SIDEBAR_WIDTH = 270
export const SIDEBAR_WIDTH_COLLAPSED = 85
export const sidebarWidth = computed(
  () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`
)