<template>
    <div class="md:col-span-12 my-">
        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mt-4">
            <div class="md:col-span-5">
                <h2 class="text-base font-semibold leading-7 text-left text-gray-900 mt-4">Actividades Realizadas</h2>
                <p class="mt-1 text-sm text-left leading-6 text-gray-600">Proporciona una detallada
                    descripción de todas las tareas, acciones y eventos llevados a cabo durante el período
                    específico del informe.</p>
            </div>

            <div class="md:col-span-7">
                <div class="mt-4">
                    <div class="max-h-[35rem] text-center mb-3 overflow-y-auto">
                        <div v-for="(realizedActivity, index) in realizedActivities" :key="index" class="flex mb-2">
                            <div class="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                                <div class="flex items-center col-span-1 mt-1">
                                    <p>{{ index + 1 }}.</p>
                                </div>
                                <textarea v-model="realizedActivities[index]['activity']"
                                    class="col-span-10 h-10 border mt-1 rounded p-1 w-full bg-gray-50 resize-none"
                                    placeholder="Escribe aquí la actividad realizada..."></textarea>
                                <button @click="removeActivity(index)" :disabled="realizedActivities.length == 1"
                                    :class="{ 'disabled-button': realizedActivities.length === 1 }"
                                    class="col-span-1 ml-2 mt-1 px-3 py-1 bg-red-500 text-white rounded">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                                <div class="max-h-[10rem] overflow-y-auto col-span-12 grid grid-flow-row auto-rows-max mt-2">
                                    <div v-if="realizedActivities[index]['subactivities'].length == 0"
                                        class="col-span-12 text-right">
                                        <button @click="addSubActivity(index)"
                                            class="col-span-1 ml-2 mt-1 px-3 py-2 bg-green-500 text-white rounded">
                                            Añadir Sub-Actividad <i class="far fa-plus-square fa-lg"></i>
                                        </button>
                                    </div>
                                    <div v-for="(subActivity, subActivityIndex) in realizedActivities[index]['subactivities']"
                                        class="col-span-12 ml-10 max-h-[10rem] grid grid-flow-col gap-x-2 overflow-y-auto">
                                        <div class="flex items-center col-span-1 mt-1">
                                            <p>{{ String.fromCharCode(97 + subActivityIndex) }}.</p>
                                        </div>
                                        <textarea v-model="realizedActivities[index]['subactivities'][subActivityIndex]"
                                            class="col-span-10 h-10 border mt-1 rounded p-1 w-full bg-gray-50 resize-none"
                                            placeholder="Escribe aquí la actividad realizada..."></textarea>
                                        <button @click="addSubActivity(index)"
                                            class="col-span-1 ml-2 mt-1 px-3 py-1 bg-green-500 text-white rounded">
                                            <i class="far fa-plus-square fa-lg"></i>
                                        </button>
                                        <button @click="removeSubActivity(index, subActivityIndex)"
                                            class="col-span-1 ml-2 mt-1 px-3 py-1 bg-red-500 text-white rounded">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-left">
                        <button @click="addActivity()" class="px-4 py-2 bg-green-500 text-left text-white rounded">Agregar
                            actividad</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="md:col-span-12 my-3 border-t border-gray-300"></div>

        <div class="flex items-center justify-end space-x-4">
            <button @click="saveReportActivities()" :disabled="loadingSave || (!newReportActivitiesAreValid)"
                :class="{ 'opacity-50 cursor-not-allowed': loadingSave || (!newReportActivitiesAreValid) }"
                class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <span v-if="loadingSave" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Guardar
            </button>
            <button @click="finishReportActivities()" :disabled="loadingFinish"
                :class="{ 'opacity-50 cursor-not-allowed': loadingFinish }"
                class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <span v-if="loadingFinish" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Siguiente
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            reportId: 0,
            loadingSave: false,
            loadingFinish: false,

            // Realized Activities
            realizedActivities: [
                {
                    activity: '',
                    subactivities: ['']
                },
            ]
        }
    },
    mounted() {
        this.reportId = this.$route.params.reportId;
        this.getReportActivities();
    },
    methods: {
        finishReportActivities() {
            this.loadingFinish = true
            let url = `${this.getApiUrl}/reports/save-report-activities`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.reportId,
                activities: this.realizedActivities
            })

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.showMsg('success', 'Informes', 'Informe actualizado satisfactoriamente');
                            this.$emit('changeCurrentStep', 3);
                            this.loadingFinish = false;
                        }, 300)
                    }
                });

        },
        getReportActivities() {
            let url = `${this.getApiUrl}/reports/get-report-activities/${this.reportId}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        if (data.activities.length != 0) {
                            this.realizedActivities = data.activities;
                        } else {
                            this.realizedActivities = [
                                {
                                    activity: '',
                                    subactivities: ['']
                                },
                            ];
                        }
                    }
                });
        },
        saveReportActivities() {
            this.loadingSave = true;
            let url = `${this.getApiUrl}/reports/save-report-activities`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.reportId,
                activities: this.realizedActivities
            })

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.showMsg('success', 'Actividades del Informe', 'Informe actualizado satisfactoriamente');
                            this.loadingSave = false;
                        }, 300)
                    }
                });
        },
        addActivity() {
            this.realizedActivities.push({
                acitvity: '',
                subactivities: ['']
            })
        },
        removeActivity(index) {
            this.realizedActivities.splice(index, 1);
        },
        addSubActivity(index) {
            this.realizedActivities[index]['subactivities'].push('')
        },
        removeSubActivity(activityIndex, subActivityIndex) {
            this.realizedActivities[activityIndex].subactivities.splice(subActivityIndex, 1);
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
        newReportActivitiesAreValid() {
            let validations = []

            return validations.every(validation => validation);
        },
    }
}
</script>

<style scoped>
.btn-save {
    font-family: 'Nunito Sans';
    background-color: #FFA500;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    ;
}

.btn-save:hover {
    background-color: #117bc3;
}

.btn-cancel {
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: normal;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.disabled-button {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>