<template>
    <div class="w-full">
        <h1 class="text-base font-bold leading-7 text-gray-900 mb-3">{{ title }}</h1>

        <div class="grid mt-3">
            <div class="max-h-[15rem] overflow-y-auto">
                    <textarea v-model="currentData.value"
                        class="h-15 border mt-1 rounded p-2 w-full bg-gray-50 resize-none" rows="4"></textarea>
            </div>
        </div>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentData: {
                value: ''
            },
            title: 'Párrafo'
        }
    },
    mounted() {
        if (Object.entries(this.data).length == 0) {
            this.currentData = {
                value: ''
            }
        }
        else {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>