<template>
    <div>
		<h3 class="text-l font-semibold mb-4">{{ title }}</h3>
        <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
    </div>
</template>
  
<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    props: {
		// data: {
		// 	type: Object,
		// 	required: true
		// },
		title: {
			type: String,
			required: true
		}
	},
    data() {
        return {
            chartData: {
                labels: ['January', 'February', 'March','January', 'February', 'March','January', 'February', 'March','January', 'February', 'March'],
                datasets: [
                    {
                        label: 'Usuarios',
                        backgroundColor: '#f87979',
                        data: [40, 20, 12]
                    },
                ]
            },
            chartOptions: {
                responsive: true,
                // maintainAspectRatio: false,
            }
        }
    }
}
</script>