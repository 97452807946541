<template>
    <div class="panel">
        <left-side-menu ref="sidebar"></left-side-menu>

        <div class="dashboard-container" ref="dashboardContainer">
            <router-view />
        </div>
    </div>
</template>

<script>
import LeftSideMenu from '../../components/sidebar/Sidebar.vue';
import { SIDEBAR_WIDTH_COLLAPSED, toggleSidebar, collapsed } from '../../components/sidebar/state'

export default {
    components: {
        LeftSideMenu
    },
    setup() {
        return { collapsed, toggleSidebar, SIDEBAR_WIDTH_COLLAPSED }
    },
    mounted() {
        document.addEventListener('click', this.handleOutsideClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleOutsideClick);
    },
    methods: {
        handleOutsideClick(event) {
            try {
                const sidebar = this.$refs.sidebar;

                if (!collapsed.value && !sidebar.$el.contains(event.target)) {
                    toggleSidebar()
                }
            } catch(err) {
                toggleSidebar(false)
            }
            
        }
    }
}

</script>

<style lang="scss">
.dashboard-container {
    margin-left: 85px
}
</style>