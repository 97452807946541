<template>
    <div>
        <Navbar title="Informes">
            <template #default>
                <button v-if="getUserData && getUserData.role != 'customer'" @click="createReport()"
                    class="bg-orange-500 text-white px-3 py-2 rounded-md shadow-md hover:bg-orange-600 text-sm">
                    Crear Informe
                </button>
            </template>
        </Navbar>
        <div class="mt-4 mr-4 px-4">
            <div class="flex justify-end">
                <div class="flex flex-col items-end">
                    <div class="relative inline-block mt-auto">
                        <select v-model="selected" @change="getReports()"
                            class="text-left text-base block appearance-none w-64 bg-white border border-gray-300 hover:border-gray-400 px-4 py-1 rounded shadow leading-tight focus:outline-none focus:border-blue-500 focus:ring">
                            <option>Todos</option>
                            <option>En Revisión</option>
                            <option>Registrado</option>
                            <option>En Planificación</option>
                            <option>En Desarrollo</option>
                            <option>Aprobado</option>
                        </select>

                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2">
            <Datatable :columns="columns" :data="data" :totalPages="totalPages" :currentPage="currentPage"
                @next-page="nextPage()" @previous-page="previousPage()" @to-page="goToPage" @search-change="searchChange"></Datatable>
        </div>

        <modal v-show="showDeleteModalReport" title="Eliminar Informe"
            description="¿Estás seguro de que quieres eliminar el informe?">
            <template #buttons>
                <button @click="deleteReport()" type="button"
                    class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 mx-3">
                    <span v-if="loadingDeletion" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Eliminar
                </button>
                <button @click="showDeleteModalReport = false" type="button"
                    class="cancel-delete-report rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Cancelar
                </button>
            </template>
        </modal>

        <modal v-show="showReprocessingReportModal" title="Reprocesar Informe"
            description="¿Estás seguro de que quieres reprocesar el informe?">
            <template #buttons>
                <button @click="reprocessReport()" type="button"
                    class="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 mx-3">
                    <span v-if="loadingReprocessing" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Reprocesar
                </button>
                <button @click="showReprocessingReportModal = false" type="button"
                    class="cancel-delete-report rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Cancelar
                </button>
            </template>
        </modal>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import Datatable from '../../components/Datatable';
import Modal from '../../components/Modal.vue'
import $ from 'jquery';
import { saveAs } from 'file-saver';
import { mapGetters } from 'vuex';

export default {
    components: { Navbar, Datatable, Modal },
    data() {
        return {
            // Delete modal User
            showDeleteModalReport: false,
            selected: 'Todos',

            columns: [
                {
                    data: 'title',
                    title: 'Título',
                    width: '20%'
                },
                {
                    data: 'location',
                    title: 'Ubicación',
                    width: '20%'
                },
                {
                    data: 'company',
                    title: 'Empresa'
                },
                {
                    data: 'creator_technician',
                    title: 'Creador'
                },
                {
                    data: 'status',
                    title: 'Estado',
                    render: (row) => {
                        if (row.status == 'Registrado' || row.status == 'En Planificación') {
                            return (
                                `<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                                    <svg class="h-4 w-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                        <circle cx="10" cy="10" r="6" />
                                    </svg>
                                    ${row.status}
                                </span>`
                            )
                        }
                        else if (row.status == 'Aprobado') {
                            return (
                                `<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 mt-2">
                                    <svg class="h-4 w-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L9 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                    Aprobado
                                </span>`
                            )
                        }

                        return (
                            `<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                                <svg class="h-4 w-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                    <circle cx="10" cy="10" r="6" />
                                </svg>
                                ${row.status}
                            </span>`
                        )
                    }
                },
                {
                    data: 'id',
                    title: 'Acciones',
                    render: (row) => {
                        if (this.getUserData && this.getUserData.role == 'customer') {
                            if (row.url)
                                return (
                                    `<div class="flex justify-start gap-4">
                                    <a href="${row.url}" target="_blank" class="download-report cursor-pointer" data-id="${row.id}">
                                        <i class="fas fa-download fa-lg"></i>
                                    </a>
                                </div>`
                                );

                            return '';
                        }

                        let download = '';
                        if (row.url && row.status == 'Aprobado') {
                            download = `<button title="Descargar Reporte" class="download-report cursor-pointer" 
                                            data-id="${row.id}" data-url="${row.url}" data-name="${row.title}.pdf">
                                            <i class="fas fa-download fa-lg"></i>
                                        </button>
                                        <a title="Reprocesar Reporte" target="_blank" class="reprocess-report cursor-pointer" data-id="${row.id}">
                                            <i class="fas fa-sync fa-lg"></i>
                                        </a>`
                        }

                        return (
                            `<div class="flex gap-4">
                                <a title="Editar Reporte" class="edit-report cursor-pointer" data-id="${row.id}">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" class="h-6 w-6" x-tooltip="tooltip">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </a>
                                <a title="Eliminar Reporte" class="delete-report cursor-pointer" data-id="${row.id}">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" class="h-6 w-6" x-tooltip="tooltip">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </a>
                                ${download}
                            </div>`
                        )
                    }
                }
            ],
            data: [],

            // Paging data
            search: '',
            currentPage: 1,
            totalPages: 0,
            limit: 10,

            // User to delete
            reportToDelete: 0,
            loadingDeletion: false,

            // Report reprocessing
            reportToReprocess: 0,
            loadingReprocessing: false,
            showReprocessingReportModal: false

        }
    },
    mounted() {
        this.getReports();

        this.$nextTick(() => {
            $(document).on("click", ".download-report", event => {
                let url = $(event.currentTarget).data("url");
                let name = $(event.currentTarget).data("name");
                saveAs(url, name);
            });

            $(document).on("click", ".edit-report", event => {
                let reportId = $(event.currentTarget).data("id");
                this.$router.push({ name: 'EditReport', params: { reportId } });
            });

            $(document).on("click", ".delete-report", event => {
                this.reportToDelete = $(event.currentTarget).data("id");
                this.showDeleteModalReport = true;
            });

            $(document).on("click", ".cancel-delete-report", event => {
                this.showDeleteModalReport = false;
            });

            $(document).on("click", '.view-report', event => {
                let reportId = $(event.currentTarget).data("id");
                this.$router.push({ name: 'ViewReport', params: { reportId } });
            });

            $(document).on("click", '.reprocess-report', event => {
                this.reportToReprocess = $(event.currentTarget).data("id");
                this.showReprocessingReportModal = true;
            });
        })
    },
    methods: {
        getReports() {
            let url = `${this.getApiUrl}/reports/list?page=${this.currentPage}&limit=${this.limit}&search=${this.search}&status=${this.selected}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.data = data.reports;
                    this.totalPages = data.totalPages
                })
        },
        createReport() {
            this.$router.push({ name: 'CreateReport' });
        },
        searchChange(searchValue) {
            this.currentPage = 1;
            this.search = searchValue;
            this.getReports();
        },
        nextPage() {
            this.currentPage += 1;
            this.getReports();
        },
        previousPage() {
            this.currentPage -= 1;
            this.getReports();
        },
        goToPage(page) {
            this.currentPage = page;
            this.getReports();
        },
        reprocessReport() {
            this.loadingReprocessing = true;
            let url = `${this.getApiUrl}/reports/reprocess-report`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.reportToReprocess
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.loadingReprocessing = false;
                            this.showReprocessingReportModal = false;
                            this.showMsg('success', 'Reprocesar informe', 'Informe procesándose');
                            this.getReports();
                        }, 300);
                    } else {
                        setTimeout(() => {
                            this.loadingReprocessing = false;
                            this.showReprocessingReportModal = false;
                            this.showMsg('error', 'Reprocesar informe', 'Error al procesar informe');
                        }, 300);
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loadingReprocessing = false;
                        this.showReprocessingReportModal = false;
                        this.showMsg('error', 'Reprocesar informe', 'Error al procesar informe');
                    }, 300);
                });
        },
        deleteReport() {
            this.loadingDeletion = true;
            let url = `${this.getApiUrl}/reports/delete`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.reportToDelete
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.loadingDeletion = false;
                            this.showDeleteModalReport = false;
                            this.getReports();
                        }, 300)
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loadingDeletion = false;
                        this.showDeleteModalReport = false;
                    }, 300)
                });
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    }
}
</script>