<template>
    <div v-if="!collapsed" class="row my-2">
        <div class="col col-4 text-center">
            <div class="header py-3 pr-0">
                <transition name="fade">
                    <img class="account-icon" src="../../assets/account.png" />
                </transition>
            </div>
        </div>

        <div class="col col-8 p-0">
            <transition name="fade">
                <div class="row py-2">
                    <div class="col col-12 text-left p-0">
                        <p class="mb-0" style="font-size: 12px;">{{ name }} {{ last_name }}</p>
                    </div>
                    <div class="col col-12 text-left p-0">
                        <a class="logout p-0" @click.stop="logout()">CERRAR SESIÓN</a>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    <div v-else class="row my-2">
        <div class="col col-4 text-center">
            <div class="header py-3 pr-0" @click.stop="toggleSidebar()">
                <i class="fas fa-bars fa-lg"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { collapsed, toggleSidebar } from './state'
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            name: '',
            last_name: ''
        }
    },
    setup(props) {
        return { collapsed, toggleSidebar, mapGetters }
    },
    mounted() {
        this.name = this.getUserData.name;
        this.last_name = this.getUserData.last_name
    },
    methods: {
        ...mapActions(['saveUserData']),
        logout() {
            this.saveUserData(null);
            this.$router.push('/auth/sign-in');
        }
    },
    computed: {
        ...mapGetters(['getUserData'])
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.header {
    display: flex;
    align-items: center;
    height: 56px;
    cursor: pointer;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    color: white;
    text-decoration: none;
    padding-left: 30px;
}

.account-icon {
    height: 30px;
    width: 30px;
}

.logout {
    color: #7e8e9d;
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 8px;
    font-size: 12px;
}

.logout:hover {
    cursor: pointer;
    text-decoration: underline;
}

.text-left {
    text-align: left;
}
</style>