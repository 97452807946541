import { createStore } from 'vuex'

export default createStore({
  state: {
    authToken: null,
    // apiUrl: 'http://localhost:4000/api',
    apiUrl: 'https://inedyc-backend.com/api',
    userData: {
      'token': '',
      'role': '',
      'email': '',
      'name': '',
      'last_name': '',
      'avatar': ''
    },
    reportTables: []
  },
  getters: {
    getApiUrl: state => state.apiUrl,
    getUserData: state => state.userData,
    getReportTables: state => state.reportTables,
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
    addReportTable(state, newTable) {
      state.reportTables.push(newTable);
    },
    updateReportTable(state, { index, newData }) {
      state.reportTables[index].data = newData;
    },
    saveAllReportTable(state, data) {
      state.reportTables = data;
    }
  },
  actions: {
    saveUserData({ commit }, userData) {
      commit('setUserData', userData)
    },
    addReportTable({ commit }, newTable) {
      commit('addReportTable', newTable)
    },
    saveReportTable({ commit }, { index, newData }) {
      commit('updateReportTable', { index, newData })
    },
    saveAllReportTable({ commit }, data) {
      commit('saveAllReportTable', data)
    }
  },
  modules: {
  }
})
