<template>
    <div class="w-full">
        <div class="grid justify-items-end mt-3">
            <button v-if="!editMode" @click="editMode = !editMode"
                class="bg-gray-700 text-white py-1 px-2 rounded hover:bg-gray-800">
                <i class="fas fa-pen"></i> Editar
            </button>
            <button v-else @click="saveTitle()" class="bg-gray-700 text-white py-1 px-2 rounded hover:bg-gray-800">
                Guardar
            </button>
        </div>

        <h3 v-if="!editMode" @dblclick="editMode = !editMode" class="text-2xl font-semibold mb-3">
            {{ value }}
        </h3>
        <div v-else>
            <div class="text-center">
                <label class="text-base font-bold">Subtítulo:</label>
            </div>
            <input v-model="value" type="text"
                class="border text-center mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
        </div>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            editMode: false,
            value: ''
        }
    },
    mounted() {
        if (this.data.value) {
            this.value = this.data.value;
        }
        else {
            this.value = 'Subtítulo';
            this.saveReportTable({ index: this.index, newData: { value: this.value}})
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
        saveTitle() {
            this.editMode = !this.editMode;
            this.saveReportTable({ index: this.index, newData: { value: this.value}})
        }
    }
}
</script>

<style scoped></style>