import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@fortawesome/fontawesome-free/js/all'
import Notifications from 'notiwind'
import '../public/index.css'
import VCalendar from 'v-calendar';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
import 'v-calendar/style.css';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App)

// Components
app.use(VCalendar, {})
app.use(Notifications)
app.use(VueViewer)
app.use(TawkMessengerVue, {
    propertyId : '65a8ac640ff6374032c19a1a',
    widgetId : '1hkde2qgj'
});

app.use(store)
app.use(router)

app.config.globalProperties.showMsg = function (type, title, msg, time = 2000) {
    this.$notify({
        group: type,
        title: title,
        text: msg
    }, time);
}

app.mount('#app');

import "bootstrap/dist/js/bootstrap.js";