<template>
    <div class="transform hover:scale-105 transition duration-300 bg-white shadow rounded sm:px-6 xl:px-8 ">
        <div class="flex flex-wrap">
            <div class="w-full sm:w-3/5 p-4 flex flex-col items-center">
                <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">{{ currentValue }}</span>
                <h3 class="text-base font-normal text-gray-500">{{ title }}</h3>
            </div>
            <div v-if="percentage >= 0"
                class="ml-5 w-full sm:w-2/5 flex items-center justify-end flex-1 text-green-500 text-base font-bold">
                {{ percentage.toFixed(2) }}%
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
            </div>
            <div v-else class="ml-5 w-full sm:w-2/5 flex items-center justify-end flex-1 text-red-500 text-base font-bold">
                {{ percentage.toFixed(2) }}%
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentValue: {
            type: Number,
            required: true
        },
        priorValue: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {

        }
    },
    computed: {
        percentage() {
            if (this.priorValue == 0 || !this.priorValue) {
                return 100;
            }

            return ((this.currentValue - this.priorValue) / this.priorValue) * 100;
        }
    }
}
</script>

<style scoped></style>