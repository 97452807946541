<template>
    <div>
        <h3 class="text-l font-semibold mb-4">{{ title }}</h3>
        <div class="overflow-x-auto">
            <table class="w-full border-collapse bg-white text-left text-sm text-gray-500">
                <colgroup v-if="data.length != 0">
                    <col v-for="(column, index) in columns" :key="index" :style="{ width: column.width }">
                </colgroup>

                <thead class="bg-gray-50">
                    <tr>
                        <th v-for="column in columns" scope="col"
                            class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>

                <tbody class="divide-y divide-gray-100 border-t border-gray-100">
                    <tr v-for="row in data" class="hover:bg-gray-50">
                        <td v-for="(column, index) in columns" :key="index" class="px-6 py-2">
                            <template v-if="(typeof column.render === 'function')">
                                <div v-html="column.render(row)"></div>
                            </template>
                            <template v-else>
                                {{ row[column.data] }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="data.length === 0" class="w-full">
                <div class="py-10 text-center">
                    <div class="mx-auto">
                        <img class="w-1/12" src="../assets/advertencia.gif" alt="No hay datos para mostrar">
                        <p class="font-bold py-4">No hay datos para mostrar</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 flex justify-start">
            <a @click="$router.push({ name: allUrl });" class="text-orange-500 hover:underline cursor-pointer">Ver Todo</a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        columns: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        totalElements: {
            type: Number,
            default: 5
        },
        allUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {}
    },
}
</script>

<style scoped>
img {
    display: inline !important;
    width: 20% !important;
}
</style>