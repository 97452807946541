<template>
    <div class="row">
        <div class="col text-center">
            <router-link :to="to" class="link py-3" :class="{ active: isActive }"
                :data-bs-toggle="collapsed ? 'tooltip' : ''" data-bs-placement="right" :title="name">
                <i class="icon" :class="icon" />
                <transition name="fade">
                    <span v-if="!collapsed">
                        <slot />
                    </span>
                </transition>
            </router-link>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { collapsed } from './state'
import { Tooltip } from 'bootstrap'

export default {
    props: {
        to: { type: String, required: true },
        icon: { type: String, required: true },
        name: { type: String, required: true }
    },
    setup(props) {
        const route = useRoute()
        const isActive = computed(() => route.path == props.to)

        return { isActive, collapsed }
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
            trigger: 'hover'
        });
    }
}
</script>

<style scoped>
slot {
    font-family: 'Nunito Sans';
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.link {
    display: flex;
    align-items: center;
    height: 56px;
    cursor: pointer;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    color: white;
    text-decoration: none;
    padding: 30px;
}

.link:hover {
    border-left: 4px solid var(--sidebar-item-hover-boder);
    background-color: var(--sidebar-item-hover);
}

.link.active {
    border-left: 4px solid var(--sidebar-item-active-border);
    background-color: var(--sidebar-item-active);
}

.link .icon {
    margin-right: 20px;
}
</style>