<template>
    <div>
      <h3 class="text-l font-semibold mb-4">{{ title }}</h3>
      <Line :data="chartData" :options="chartOptions"></Line>
    </div>
  </template>
  
  <script>
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js'
  import { Line } from 'vue-chartjs'
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )
  
  export default {
    components: { Line },
    props: {
      datasets: {
        type: Array,
        required: true
      },
      labels: {
        type: Array,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
        },
        colors: [
            {
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255,99,132,1)',
            },
            {
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
            },
            {
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
            },
        ],
      };
    },
    computed: {
      chartData() {
        return {
          labels: this.labels,
          datasets: this.datasets.map((dataset, index) => {
            const color = this.colors[index % this.colors.length];
            return {
                label: dataset.label,
                backgroundColor: dataset.backgroundColor || color.backgroundColor,
                borderColor: dataset.borderColor || color.borderColor,
                borderWidth: dataset.borderWidth || 1,
                data: dataset.data,
            }
        }),
        }
      }
    }
  };
  </script>