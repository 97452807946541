<template>
    <div>
        <Navbar title="Tablero">
            <template #default>
                <button v-if="getUserData && getUserData.role != 'customer'" @click="createReport()"
                    class="bg-orange-500 text-white px-3 py-2 rounded-md shadow-md hover:bg-orange-600 text-sm">
                    Crear Informe
                </button>
            </template>
        </Navbar>
        <div class="m-4">
            <div class="px-4">
                <div class="flex justify-end">
                    <PeriodSelector class="w-70 mr-2" :value="selectedGlobalTime" @interval-change="onIntervalChange"/>
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-1/2 p-4">
                    <div class="bg-white rounded-lg shadow-md p-6 flex-grow h-full flex items-center justify-center">
                        <!-- <line-chart :data="reportsSummary" title="Resumen de Informes" /> -->
                        <div class="w-full">
                            <multi-dataset-line-chart :datasets="reportsSummary.datasets" :labels="reportsSummary.labels" title="Estadísticas Mensuales" />
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 p-4">
                    <div class="w-full grid grid-rows-4 grid-flow-col gap-y-3 h-full">
                        <Card title="Total de Usuarios" :currentValue="totalUsers.currentPeriod.toFixed(2)" :priorValue="totalUsers.priorPeriod.toFixed(2)"/>
                        <Card title="Informes en Progreso" :currentValue="reportsInProgress.currentPeriod.toFixed(2)" :priorValue="reportsInProgress.priorPeriod.toFixed(2)"/>
                        <Card title="Transformadores Intervenidos" :currentValue="intervenedTransformers.currentPeriod.toFixed(2)" :priorValue="intervenedTransformers.priorPeriod.toFixed(2)"/>
                        <Card title="Generadores Intervenidos" :currentValue="intervenedGenerators.currentPeriod.toFixed(2)" :priorValue="intervenedGenerators.priorPeriod.toFixed(2)"/>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-1/2 p-4">
                    <div class="bg-white rounded-lg shadow-md p-6 flex-grow h-full">
                        <pie-chart :data="reportsByStatus" title="Resumen de Informes por Estado" />
                    </div>
                </div>
                <div class="w-full lg:w-1/2 p-4">
                    <div class="bg-white rounded-lg shadow-md p-4 h-full">
                        <TopTable :title="titleLastCustomers" :columns="columnsLastCustomers" :data="dataLastCustomers"
                            :total-elements="totaLastCustomers" :allUrl="usersRouteName"/>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-1/2 p-4">
                    <div class="bg-white rounded-lg shadow-md p-4">
                        <TopTable :title="titleReportsByTechnician" :columns="columnsReportsByTechnician"
                            :data="dataReportsByTechnician" :total-elements="totalElementsReportsByTechnician" 
                            :allUrl="reportsRouteName"/>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import PeriodSelector from '@/components/PeriodSelector.vue';
import Card from '@/components/Card.vue';
import Navbar from '../../components/Navbar';
import BarChart from '@/components/charts/BarChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
import MultiDatasetLineChart from '@/components/charts/MultiDatasetLineChart.vue';
import PieChart from '@/components/charts/PieChart.vue';
import TopTable from '@/components/TopTable.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        Navbar, PeriodSelector, BarChart, LineChart, MultiDatasetLineChart, PieChart, TopTable, Card
    },
    data() {
        return {
            selectedGlobalTime: 'Este mes',

            // Total Users
            totalUsers: {
                currentPeriod: 0,
                priorPeriod: 0
            },

            // Reports In Progress
            reportsInProgress: {
                currentPeriod: 0,
                priorPeriod: 0
            },

            // Intervened Transformers
            intervenedTransformers: {
                currentPeriod: 0,
                priorPeriod: 0
            },

            // Intervened Generators
            intervenedGenerators: {
                currentPeriod: 0,
                priorPeriod: 0
            },

            // Top Tables
            // Reports assigned by Technician
            reportsRouteName : 'ReportList',
            titleReportsByTechnician: 'Resumen de Reportes Asignados',
            columnsReportsByTechnician: [
                {
                    width: '70%',
                    data: 'name',
                    title: 'Técnico',
                    render: (row) => {
                        return (
                            `<div class="flex items-center">
                                <div>
                                    <div class="text-sm font-medium text-gray-900">
                                        ${row.name} ${row.last_name}
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        ${row.email}
                                    </div>
                                </div>
                            </div>`
                        )
                    },
                },
                {
                    width: '30%',
                    data: 'reportCount',
                    title: 'Informes Asignados',
                }
            ],
            dataReportsByTechnician: [],
            totalElementsReportsByTechnician: 5,

            // Last custmers registered
            usersRouteName : 'UsersList',
            titleLastCustomers: 'Últimos clientes registrados',
            columnsLastCustomers: [
                {
                    data: 'name',
                    title: 'Cliente',
                    width: '60%',
                    render: (row) => {
                        return (
                            `<div class="flex items-center">
                                <div>
                                    <div class="text-sm font-medium text-gray-900">
                                        ${row.name} ${row.last_name}
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        ${row.email}
                                    </div>
                                </div>
                            </div>`
                        )
                    },
                },
                {
                    width: '40%',
                    data: 'company',
                    title: 'Empresa',
                }
            ],
            dataLastCustomers: [],
            totaLastCustomers: 5,

            // Reports by status
            reportsByStatus: {
                labels: [],
                data: []
            },

            // Reports Summary
            reportsSummary: {
                labels: [],
                datasets: [
                    {
                        label: "Informes mensuales",
                        data: []
                    },
                    {
                        label: "Transformadores intervenidos",
                        data: []
                    },
                    {
                        label: "Generadores intervenidos",
                        data: []
                    }
                   
                ]
            }
        }
    },
    mounted() {
        this.getDashboardData();
    },
    methods: {
        getDashboardData() {
            let url = `${this.getApiUrl}/get-dashboard-data/${this.selectedGlobalTime}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.dataLastCustomers = data.lastRegisteredCustomers
                    this.reportsByStatus = data.reportsByStatus;
                    this.totalUsers = data.totalUsers;
                    this.reportsInProgress = data.reportsInProgress;
                    this.intervenedTransformers = data.intervenedTransformers;
                    this.intervenedGenerators = data.intervenedGenerators;
                    this.dataReportsByTechnician = data.topAssignedTechnicians;
                    this.reportsSummary = {
                        labels: data.reportsSummary.labels,
                        datasets: [
                            {
                                label: "Informes mensuales",
                                data: data.reportsSummary.reportData
                            },
                            {
                                label: "Transformadores intervenidos",
                                data: data.reportsSummary.transformerData
                            },
                            {
                                label: "Generadores intervenidos",
                                data: data.reportsSummary.generatorData
                            }
                        ]
                    }
                })
        },
        onIntervalChange(value) {
            this.selectedGlobalTime = value;
            this.getDashboardData();
        },
        createReport() {
            this.$router.push({ name: 'CreateReport' });
        },
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    },
}
</script>

<style scoped></style>