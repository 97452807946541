<template>
    <div class="lg:col-span-2">
        <div class="grid justify-items-end mt-3">
            <button v-if="!editMode" @click="editMode = !editMode"
                class="bg-gray-700 text-white py-1 px-2 rounded hover:bg-gray-800">
                <i class="fas fa-pen"></i> Editar
            </button>
            <button v-else="editMode" @click="editMode = !editMode"
                class="bg-gray-700 text-white py-1 px-2 rounded hover:bg-gray-800">
                Guardar
            </button>
        </div>

        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
            <div class="md:col-span-2">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Posición de Tap</label>
                </div>
            </div>
            <div class="md:col-span-4">
                <div class="grid text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
                    <div class="md:col-span-12">
                        <div class="flex items-center justify-center h-full">
                            <label class="text-base font-bold">Voltaje</label>
                        </div>
                    </div>
                    <div class="md:col-span-6">
                        <div class="flex items-center justify-center h-full">
                            <label class="text-base font-bold">Primario</label>
                        </div>
                    </div>
                    <div class="md:col-span-6">
                        <div class="flex items-center justify-center h-full">
                            <label class="text-base font-bold">Secundario</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:col-span-4">
                <div class="grid gap-x-5 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
                    <div class="md:col-span-12">
                        <div class="flex items-center justify-center h-full">
                            <label class="text-base font-bold">Relacion medida</label>
                        </div>
                    </div>
                    <div class="md:col-span-4">
                        <div v-if="!editMode" class="text-center" @dblclick="editMode = !editMode">
                            <label class="text-base font-bold">{{ currentData.columns.h1h4 }}</label>
                        </div>
                        <div v-else class="text-center">
                            <input v-model="currentData.columns.h1h4" type="text"
                                class="border text-center mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                    <div class="md:col-span-4">
                        <div v-if="!editMode" class="text-center" @dblclick="editMode = !editMode">
                            <label class="text-base font-bold">{{ currentData.columns.h2h5 }}</label>
                        </div>
                        <div v-else class="text-center">
                            <input v-model="currentData.columns.h2h5" type="text"
                                class="border text-center mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                    <div class="md:col-span-4">
                        <div v-if="!editMode" class="text-center" @dblclick="editMode = !editMode">
                            <label class="text-base font-bold">{{ currentData.columns.h3h6 }}</label>
                        </div>
                        <div v-else class="text-center">
                            <input v-model="currentData.columns.h3h6" type="text"
                                class="border text-center mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Relación Teórica</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">% Error</label>
                </div>
            </div>
        </div>

        <div class="max-h-[14rem] overflow-y-auto">
            <div v-for="value in currentData.rows"
            class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 ">
            <div class="md:col-span-2">
                <div class="flex items-center justify-center h-full">
                    <input v-model="value.tapPosition" type="text"
                        class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                </div>
            </div>
            <div class="md:col-span-4">
                <div class="grid gap-x-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
                    <div class="md:col-span-6">
                        <div class="flex items-center justify-center h-full">
                            <input v-model="value.primaryVoltage" type="text"
                                class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                    <div class="md:col-span-6">
                        <div class="flex items-center justify-center h-full">
                            <input v-model="value.secondaryVoltage" type="text"
                                class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:col-span-4">
                <div class="grid gap-x-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
                    <div class="md:col-span-4">
                        <div class="text-center">
                            <input v-model="value.measuredRatioH1H4" type="text"
                                class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                    <div class="md:col-span-4">
                        <div class="text-center">
                            <input v-model="value.measuredRatioH2H5" type="text"
                                class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                    <div class="md:col-span-4">
                        <div class="text-center">
                            <input v-model="value.measuredRatioH3H6" type="text"
                                class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <input v-model="value.theoreticalRatio" type="text"
                        class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <input v-model="value.errorPercentage" type="text"
                        class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                </div>
            </div>
        </div>
        </div>

        <div class="flex mt-3 items-center justify-end space-x-4">
            <button @click="addRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-plus"></i>
            </button>
            <button @click="removeRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-minus"></i>
            </button>
        </div>

        <div class="mt-2">
            <label class="text-base font-semibold leading-7 text-gray-900 block text-left">Descripción:</label>
            <div class="flex items-center justify-center">
                <input v-model="currentData.description" type="text"
                    class="border mt-1 rounded p-2 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Relación de transformación',
            editMode: false,
            currentData: {
                columns: {
                    h1h4: '',
                    h2h5: '',
                    h3h6: ''
                },
                rows: [],
                description: ''
            }
        }
    },
    mounted() {
        if (Object.entries(this.data).length == 0) {
            this.currentData = {
                columns: {
                    h1h4: 'H1 - H4',
                    h2h5: 'H2 - H5',
                    h3h6: 'H3 - H6'
                },
                rows: [{
                    tapPosition: '',
                    primaryVoltage: '',
                    secondaryVoltage: '',
                    measuredRatioH1H4: '',
                    measuredRatioH2H5: '',
                    measuredRatioH3H6: '',
                    theoreticalRatio: '',
                    theoreticalRatio: '',
                    errorPercentage: ''
                }]
            }
        }
        else {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
        addRow() {
            this.currentData.rows.push({
                tapPosition: '',
                primaryVoltage: '',
                secondaryVoltage: '',
                measuredRatioH1H4: '',
                measuredRatioH2H5: '',
                measuredRatioH3H6: '',
                theoreticalRatio: '',
                theoreticalRatio: '',
                errorPercentage: ''
            });
        },
        removeRow() {
            this.currentData.rows.pop();
        }
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {

                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>