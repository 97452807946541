<template>
    <div>
        <Navbar title="Crear Usuario"></Navbar>
        <div class="mt-4">
            <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 mx-4">
                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">
                    <!-- <h2 class="text-base text-left font-semibold leading-7 text-gray-900 mx-20">Perfil del Usuario</h2> -->

                    <div class="lg:col-span-2 mx-20">
                        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Nombre</label>
                                </div>
                                <input v-model="user.name" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Apellido</label>
                                </div>
                                <input v-model="user.last_name" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label @input="clearEmailError" class="text-base font-bold">
                                        Email
                                    </label>
                                </div>
                                <input v-model="user.email" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none"
                                    placeholder="usuario@empresa.com" />
                                <div class="text-left">
                                    <span v-if="user.email.length > 0 && !isValidEmail()" class="text-red-600">
                                        Email no válido
                                    </span>
                                </div>
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Rol del usuario</label>
                                </div>
                                <div class="relative">
                                    <select v-model="user.role"
                                        class="block mt-1 w-full appearance-none bg-gray-50 border border-gray-300 p-1 rounded leading-tight focus:outline-none focus:border-blue-500">
                                        <option value="superadmin">Administador</option>
                                        <option value="technician">Técnico</option>
                                        <option value="customer">Cliente</option>
                                    </select>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center p-1 text-gray-700">
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Grado de estudios académicos</label>
                                </div>
                                <div class="relative">
                                    <select v-model="user.degree"
                                        class="block mt-1 w-full appearance-none bg-gray-50 border border-gray-300 p-1 rounded leading-tight focus:outline-none focus:border-blue-500">
                                        <option v-for="academicDegree in academicDegrees" :value="academicDegree.abbreviation">{{ academicDegree.degree }}</option>
                                    </select>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center p-1 text-gray-700">
                                        <i class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="md:col-span-5"></div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Empresa</label>
                                </div>
                                <form class="flex items-center w-full mt-1">
                                    <div class="relative w-full">
                                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <input v-model="companySearch" @input="handleSearch()" type="text"
                                                class="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="Buscar la empresa..." required>
                                            <ul v-show="showSuggestions" ref="suggestionsList"
                                                class="bg-white border border-gray-300 mt-2 w-full rounded-lg shadow-lg absolute z-5"
                                                style="top: calc(100% + 8px);">
                                                <li v-for="suggestion in companySuggestions" :key="suggestion.id"
                                                    @click="selectSuggestion(suggestion)"
                                                    class="py-2 px-4 cursor-pointer hover:bg-gray-100">
                                                    <div class="text-left">
                                                        <p>{{ suggestion.company_name}}</p>
                                                    </div>
                                                </li>
                                                <li v-if="showSuggestions && companySuggestions.length == 0"
                                                    class="py-3 px-4 cursor-pointer hover:bg-gray-100"
                                                    @click="createNewCompany()">
                                                    <div class="text-left">
                                                        <p>Crear nueva empresa: {{ companySearch }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Número Telefónico</label>
                                </div>
                                <input v-model="user.phone_number" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none"
                                    placeholder="0999999999" />
                                <div class="text-left">
                                    <span v-if="user.phone_number.length > 0 && !isValidPhone()" class="text-red-600">
                                        Teléfono no válido
                                    </span>
                                </div>
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Departamento</label>
                                </div>
                                <input v-model="user.department" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            
                        </div>
                    </div>
                    <div class="lg:col-span-2 mx-20">
                        <div class="flex mt-5 items-center justify-start space-x-4">
                            <button @click="goBack"
                                class="bg-white ring-1 ring-inset ring-gray-300 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                Atrás
                            </button>
                            <button @click="createUser()" :disabled="loadingSave || !isValid"
                                :class="{ 'opacity-50 cursor-not-allowed': loadingSave || !isValid }"
                                class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                <span v-if="loadingSave" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import { mapGetters } from 'vuex';

export default {
    components: {
        Navbar
    },
    data() {
        return {
            userId: 0,
            user: {
                name: '',
                last_name: '',
                email: '',
                role: '',
                phone_number: '',
                company_id: 0,
                department: '',
                degree: ''
            },

            // Company Search
            companySearch: '',
            showSuggestions: false,
            companySuggestions: [],
            companySelected: null,

            showEmailError: false,
            loadingSave: false,
            academicDegrees: [
                { degree: "Ingeniero", abbreviation: "Ing." },
                { degree: "Doctor", abbreviation: "Dr." },
                { degree: "Licenciado", abbreviation: "Lic." },
                { degree: "Tecnólogo", abbreviation: "Tec." },
                { degree: "Contador Público", abbreviation: "C.P." },
                { degree: "Otro", abbreviation: "Otro"}
            ]
        }
    },
    mounted() {
    },
    methods: {
        createUser() {
            this.loadingSave = true;
            let url = `${this.getApiUrl}/users/register`;
            let token = this.getUserData.token;

            this.user.company_id = this.companySelected.id;
            let body = JSON.stringify(this.user);

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response => {
                    if (response.status != 201 && response.status != 400) {
                        throw new Error('Error de cliente: Solicitud incorrecta');
                    }
                    else {
                        return response.json()
                    }
                })
                .then(data => {
                    let userId = data.userId;
                    if (data.status == 201) {
                        setTimeout(() => {
                            this.showMsg('success', 'Crear Usuario', 'Usuario creado satisfactoriamente');
                            this.loadingSave = false;
                            this.$router.push({ name: 'EditUser', params: { userId } });
                        }, 300)
                    }
                    else if(data.status == 400 && data.message == 'User already exists') {
                        this.loadingSave = false;
                        this.showMsg('error', 'Crear Usuario', 'Usuario con ese email ya existe');
                    }
                })
                .catch(error => {
                    this.loadingSave = false;
                    this.showMsg('error', 'Crear Usuario', 'Error al crear el usuario');
                });
        },
        goBack() {
            this.$router.push({ name: 'UsersList' });
        },
        isValidEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(this.user.email);
        },
        isValidPhone() {
            const phoneNumberPattern = /^0\d{9}$/;
            return phoneNumberPattern.test(this.user.phone_number);
        },
        createNewCompany() {
            this.$router.push({ name: 'CreateCompany' });
        },
        async handleSearch() {
            this.showSuggestions = this.companySearch.length >= 1;
            if (this.showSuggestions) {
                let url = `${this.getApiUrl}/company/companies-suggestions/${this.companySearch}`;
                let token = this.getUserData.token;

                await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': token
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.companySuggestions = data.companies;
                    })
            }
        },
        selectSuggestion(suggestion) {
            this.companySearch = suggestion.company_name;
            this.companySelected = suggestion;
            this.showSuggestions = false;
        },
        handleClickOutside(event) {
            const suggestionsList = this.$refs.suggestionsList;
            if (suggestionsList && !this.$refs.suggestionsList.contains(event.target)) {
                this.showSuggestions = false;
            }
        },
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
        isValid() {
            let validations = [
                this.user.name.length != 0,
                this.user.last_name.length != 0,
                this.user.email.length != 0,
                this.user.role.length != 0,
                this.companySelected != null,
                this.isValidEmail(),
                this.isValidPhone()
            ]

            return validations.every(validation => validation);
        }
    }
}
</script>