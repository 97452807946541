<template>
    <div>
        <Navbar title="Usuarios">
            <template #default>
                <button @click="createUser()" class="bg-orange-500 text-white px-3 py-2 rounded-md shadow-md hover:bg-orange-600 text-sm">
                    Crear Usuario
                </button>
            </template>
        </Navbar>
        <div class="mt-4 mr-4 px-4">
            <div class="flex justify-end">
                <div class="flex flex-col items-end">
                    <div class="relative inline-block mt-auto">
                        <select v-model="selected" @change="getUsers()"
                            class="text-left text-base block appearance-none w-64 bg-white border border-gray-300 hover:border-gray-400 px-4 py-1 rounded shadow leading-tight focus:outline-none focus:border-blue-500 focus:ring">
                            <option value="all">Todos</option>
                            <option value="superadmin">Administrador</option>
                            <option value="technician">Técnico</option>
                            <option value="customer">Cliente</option>
                        </select>

                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <Datatable :columns="columns" :data="data" :totalPages="totalPages" :currentPage="currentPage"
                @next-page="nextPage()" @previous-page="previousPage()" @to-page="goToPage" @search-change="searchChange"></Datatable>
        </div>

        <modal v-show="showDeleteModalUser" title="Eliminar Usuario"
            description="¿Estás seguro de que quieres eliminar el usuario?">
            <template #buttons>
                <button @click="deleteUser()" type="button"
                    class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 mx-3">
                    <span v-if="loadingDeletion" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Eliminar
                </button>
                <button @click="showDeleteModalUser = false" type="button"
                    class="cancel-delete-user rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Cancelar
                </button>
            </template>
        </modal>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import Datatable from '../../components/Datatable';
import Modal from '../../components/Modal.vue'
import $ from 'jquery';
import { mapGetters } from 'vuex';

export default {
    components: { Navbar, Datatable, Modal },
    data() {
        return {
            // Delete modal User
            showDeleteModalUser: false,
            selected: 'all',

            // Roles
            rolesMapping: {
                'superadmin': 'Administrador',
                'customer': 'Cliente',
                'technician': 'Técnico'
            },

            columns: [
                {
                    data: 'name',
                    title: 'Nombre',
                    width: '30%',
                    render: (row) => {
                        return (
                            `<div class="flex gap-3 px-6 py-2 font-normal text-gray-900">
                                <div class="text-sm w-full">
                                    <div class="font-medium text-gray-700">${row.name} ${row.last_name}</div>
                                    <div class="text-gray-400">${row.email}</div>
                                </div>
                            </div>`
                        )
                    }
                },
                {
                    data: 'company',
                    title: 'Empresa',
                    width: '30%'
                },
                {
                    data: 'role',
                    title: 'Rol',
                    render: (row) => {
                        return this.rolesMapping[row.role];
                    }
                },
                {
                    data: 'updatedAt',
                    title: 'Última sesión',
                    render: (row) => {
                        return this.transformDateTime(row.updatedAt);
                    }
                },
                {
                    data: 'id',
                    title: 'Acciones',
                    render: (row) => {
                        return (
                            `<div class="flex gap-4">
                                <a class="delete-user cursor-pointer" data-id="${row.id}">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" class="h-6 w-6" x-tooltip="tooltip">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </a>
                                <a class="edit-user cursor-pointer" data-id="${row.id}">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" class="h-6 w-6" x-tooltip="tooltip">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </a>
                            </div>`
                        )
                    }
                }
            ],
            data: [],


            // Paging data
            search: '',
            currentPage: 1,
            totalPages: 0,
            limit: 10,

            // User to delete
            userToDelete: 0,
            loadingDeletion: false

        }
    },
    mounted() {
        this.getUsers();

        this.$nextTick(() => {
            $(document).on("click", ".edit-user", event => {
                let userId = $(event.currentTarget).data("id");
                this.$router.push({ name: 'EditUser', params: { userId } });
            });

            $(document).on("click", ".delete-user", event => {
                this.userToDelete = $(event.currentTarget).data("id");
                this.showDeleteModalUser = true;
            });

            $(document).on("click", ".cancel-delete-user", event => {
                this.showDeleteModalUser = false;
            });
        })
    },
    methods: {
        getUsers() {
            let url = `${this.getApiUrl}/users/list?page=${this.currentPage}&limit=${this.limit}&search=${this.search}&role=${this.selected}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.data = data.users;
                    this.totalPages = data.totalPages
                })
        },
        transformDateTime(dateTimeString) {
            let dateObj = new Date(dateTimeString);
            let year = dateObj.getFullYear();
            let month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            let day = dateObj.getDate().toString().padStart(2, '0');
            let hours = dateObj.getHours().toString().padStart(2, '0');
            let minutes = dateObj.getMinutes().toString().padStart(2, '0');

            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
        },
        createUser() {
            this.$router.push({ name: 'CreateUser'});
        },
        searchChange(searchValue) {
            this.currentPage = 1;
            this.search = searchValue;
            console.log(this.search);
            this.getUsers();
        },
        nextPage() {
            this.currentPage += 1;
            this.getUsers();
        },
        previousPage() {
            this.currentPage -= 1;
            this.getUsers();
        },
        goToPage(page) {
            this.currentPage = page;
            this.getUsers();
        },
        deleteUser() {
            this.loadingDeletion = true;
            let url = `${this.getApiUrl}/users/delete`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                userId: this.userToDelete
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.loadingDeletion = false;
                            this.showDeleteModalUser = false;
                            this.getUsers();
                        }, 300)
                    }

                })
                .catch(() => {
                    setTimeout(() => {
                        this.loadingDeletion = false;
                        this.showDeleteModalUser = false;
                    }, 300)
                });
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    }
}
</script>