<template>
    <div class="w-full">
        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>
        <textarea v-model="value" class="border mt-1 rounded p-1 w-full h-20 bg-gray-50 resize-none">
        </textarea>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Análisis de Resultados',
            value: ''
        }
    },
    mounted() {
        if (this.data.value) {
            this.value = this.data.value;
        }
        else {
            this.value = '';
            this.saveReportTable({ index: this.index, newData: { value: this.value } })
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
    },
    watch: {
        value: {
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: { value: newValue } });
            },
        }
    }
}
</script>

<style scoped></style>