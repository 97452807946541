<template>
    <div class="lg:col-span-2">
        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Equipo</label>
                </div>
                <input v-model="currentData.equipment" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Marca</label>
                </div>
                <input v-model="currentData.brand" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center font-bold">
                    <label class="text-base">Serie</label>
                </div>
                <input v-model="currentData.equipmentSerie" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

            <div class="md:col-span-3">
                <div class="text-center font-bold">
                    <label class="text-base">Potencia</label>
                </div>
                <input v-model="currentData.power" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center font-bold">
                    <label class="text-base">Conexión</label>
                </div>
                <input v-model="currentData.connection" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center font-bold">
                    <label class="text-base">Voltaje AT</label>
                </div>
                <input v-model="currentData.ATVoltage" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center font-bold">
                    <label class="text-base">Voltaje BT</label>
                </div>
                <input v-model="currentData.BTVoltage" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

            <div class="md:col-span-3">
                <div class="text-center font-bold">
                    <label class="text-base">Temperatura de Prueba</label>
                </div>
                <input v-model="currentData.testTemperature" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center font-bold">
                    <label class="text-base">% Húmedad Relativa</label>
                </div>
                <input v-model="currentData.relativeHumidity" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center font-bold">
                    <label class="text-base">Clima</label>
                </div>
                <input v-model="currentData.weather" type="text"
                    class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

            <div class="md:col-span-5">
                <div class="text-center font-bold">
                    <label class="text-base">Lugar de Prueba</label>
                </div>
                <input v-model="currentData.testPlace" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

            <div class="md:col-span-5">
                <div class="text-center font-bold">
                    <label class="text-base">Realizado por</label>
                </div>
                <input v-model="currentData.realizedBy" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">Resultados de las pruebas en el Transformador</h4>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
            <div class="md:col-span-2">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Prueba N.</label>
                </div>
            </div>
            <div class="md:col-span-2">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Tipo de Prueba</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Rojo</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Azul</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Negro</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Prueba KV</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Capacitancia (nf)</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Factor de Disipación</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Factor de Corrección</label>
                </div>
            </div>
            <div class="md:col-span-1">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Factor de Disipación a 20°C</label>
                </div>
            </div>
        </div>

        <div class="max-h-[13rem] overflow-y-auto">
            <div v-for="value in currentData.rows"
                class="grid gap-x-5 gap-y-1 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-1">
                <div class="md:col-span-2">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.test" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-2">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.testType" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.red" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.blue" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.black" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.testKV" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.capacitance" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.dissipation" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.correction" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-1">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.dissipationCelsius" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
            </div>
        </div>


        <div class="flex mt-3 items-center justify-end space-x-4 mb-3">
            <button @click="addRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-plus"></i>
            </button>
            <button @click="removeRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-minus"></i>
            </button>
        </div>

        <div class="my-2">
            <label class="text-base font-semibold leading-7 text-gray-900 block text-left">Descripción:</label>
            <div class="flex items-center justify-center">
                <input v-model="currentData.description" type="text"
                    class="border mt-1 rounded p-2 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="border-b border-gray-300 mb-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Ensayo de Factor de Potencia',
            currentData: []
        }
    },
    mounted() {
        if (Object.entries(this.data).length == 0) {
            this.currentData = {
                equipment: '',
                brand: '',
                equipmentSerie: '',
                power: '',
                connection: '',
                ATVoltage: '',
                BTVoltage: '',
                testTemperature: '',
                relativeHumidity: '',
                weather: '',
                testPlace: '',
                realizedBy: '',
                rows: [{
                    test: '',
                    testType: '',
                    red: '',
                    blue: '',
                    black: '',
                    testKV: '',
                    capacitance: '',
                    dissipation: '',
                    correction: '',
                    dissipationCelsius: ''
                }],
                description: ''
            };
        }
        else {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
        addRow() {
            this.currentData.rows.push({
                test: '',
                testType: '',
                red: '',
                blue: '',
                black: '',
                testKV: '',
                capacitance: '',
                dissipation: '',
                correction: '',
                dissipationCelsius: ''
            })
        },
        removeRow() {
            this.currentData.rows.pop()
        }
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>