<template>
    <div class="lg:col-span-2">
        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Ensayo</label>
                </div>
            </div>
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">AT-BT</label>
                </div>
            </div>
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">AT-GND</label>
                </div>
            </div>
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">BT-GND</label>
                </div>
            </div>
        </div>

        <div class="max-h-[13rem] overflow-y-auto">
            <div v-for="value in currentData.rows"
                class="grid gap-x-5 gap-y-1 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-1">
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.test" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.atbt" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.atgnd" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.btgnd" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
            </div>
        </div>


        <div class="flex mt-3 items-center justify-end space-x-4">
            <button @click="addRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-plus"></i>
            </button>
            <button @click="removeRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-minus"></i>
            </button>
        </div>

        <div class="my-2">
            <label class="text-base font-semibold leading-7 text-gray-900 block text-left">Descripción:</label>
            <div class="flex items-center justify-center">
                <input v-model="currentData.description" type="text"
                    class="border mt-1 rounded p-2 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Resistencia de Aislamiento',
            currentData: {
                rows: [],
                description: ''
            }
        }
    },
    mounted() {
        if (Object.entries(this.data).length == 0) {
            this.currentData = {
                rows: [{
                    test: '',
                    atbt: '',
                    atgnd: '',
                    btgnd: '',
                }],
                description: ''
            }
        } else {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
        addRow() {
            this.currentData.rows.push({
                test: '',
                atbt: '',
                atgnd: '',
                btgnd: '',
            });
            this.saveReportTable({ index: this.index, newData: this.currentData });
        },
        removeRow() {
            this.currentData.rows.pop()
            this.saveReportTable({ index: this.index, newData: this.currentData });
        }
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>