<template>
    <div class="lg:col-span-2">
        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Ensayo</label>
                </div>
                <input v-model="currentData.test" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Norma</label>
                </div>
                <input v-model="currentData.standard" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Equipo</label>
                </div>
                <input v-model="currentData.equipment" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Medida promedio inicial</label>
                </div>
                <input v-model="currentData.averageInitialMeasurement" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Medida promedio Posterior al tratamiento</label>
                </div>
                <input v-model="currentData.averagePostTreatmentMeasurement" type="text" class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="my-2">
            <label class="text-base font-semibold leading-7 text-gray-900 block text-left">Descripción:</label>
            <div class="flex items-center justify-center">
                <input v-model="currentData.description" type="text" class="border mt-1 rounded p-2 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="border-b border-gray-300 mb-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Diagnóstico de Aceite - Rigidez Dieléctrica',
            currentData: {
                test: '',
                standard: '',
                equipment: '',
                averageInitialMeasurement: '',
                averagePostTreatmentMeasurement: '',
                description: ''
            }
        }
    },
    mounted() {
        if (Object.entries(this.data).length > 0) {
            this.currentData = this.data;
        } else {
            this.currentData = {
                test: '',
                standard: '',
                equipment: '',
                averageInitialMeasurement: '',
                averagePostTreatmentMeasurement: '',
                description: ''
            }
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped>
</style>