<template>
    <div class="custom-div sticky flex justify-between items-center">
        <div class="title">{{ title }}</div>
        <div class="actions">
            <slot></slot>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>
  
<style scoped>
.custom-div {
    background-color: #ffffff;
    font-size: 20px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}

.sticky {
  position: sticky;
  top: 0;   
  z-index: 1;
}
</style>
  