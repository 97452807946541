<template>
    <div>
        <div class="flex mt-4 items-center justify-center">
            <div class="step w-1/5 text-center px-6" @click="changeStep(1)">
                <div class="rounded-lg flex items-center justify-center border border-gray-200"
                    :class="currentStep >= 1 ? 'bg-green-400' : 'bg-gray-300'">
                    <div class="w-1/3 bg-transparent h-10 flex items-center justify-center icon-step">
                        <i class="fas fa-info-circle" style="color: white;"></i>
                    </div>
                    <div class="w-2/3 bg-gray-200 h-12 flex flex-col items-center justify-center px-1 body-step">
                        <h2 class="font-bold text-sm">Información General</h2>
                    </div>
                </div>
            </div>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path :fill="currentStep >= 2 ? '#4BDE81' : ''"
                        d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
                </svg>
            </div>


            <div class="step w-1/5 text-center px-6" @click="changeStep(2)">
                <div class="rounded-lg flex items-center justify-center border border-gray-200"
                    :class="currentStep >= 2 ? 'bg-green-400' : 'bg-gray-300'">
                    <div v-if="currentStep < 2" class="w-1/3 bg-transparent h-10 flex items-center justify-center icon-step">
                        <i class="fas fa-tasks"></i>
                    </div>
                    <div v-else class="w-1/3 bg-transparent h-10 flex items-center justify-center icon-step">
                        <i class="fas fa-tasks" style="color: white"></i>
                    </div>
                    <div class="w-2/3 bg-gray-200 h-12 flex flex-col items-center justify-center body-step">
                        <h2 class="font-bold text-sm">Actividades Realizadas</h2>
                    </div>
                </div>
            </div>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path :fill="currentStep >= 3 ? '#4BDE81' : ''"
                        d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
                </svg>
            </div>

            <div class="step w-1/5 text-center px-6" @click="changeStep(3)">
                <div class="rounded-lg flex items-center justify-center border border-gray-200"
                    :class="currentStep >= 3 ? 'bg-green-400' : 'bg-gray-300'">
                    <div v-if="currentStep < 3" class="w-1/3 bg-transparent h-10 flex items-center justify-center icon-step">
                        <i class="fas fa-tasks"></i>
                    </div>
                    <div v-else class="w-1/3 bg-transparent h-10 flex items-center justify-center icon-step">
                        <i class="fas fa-tasks" style="color: white"></i>
                    </div>
                    <div class="w-2/3 bg-gray-200 h-12 flex flex-col items-center justify-center px-1 body-step">
                        <h2 class="font-bold text-sm">Desarrollo</h2>
                    </div>
                </div>
            </div>

            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path :fill="currentStep >= 4 ? '#4BDE81' : ''"
                        d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
                </svg>
            </div>

            <div class="step w-1/5 text-center px-6" @click="changeStep(4)">
                <div class="rounded-lg flex items-center justify-center border border-gray-200"
                    :class="currentStep >= 4 ? 'bg-green-400' : 'bg-gray-300'">
                    <div v-if="currentStep < 4" class="w-1/3 bg-transparent h-10 flex items-center justify-center icon-step">
                        <i class="fas fa-tasks"></i>
                    </div>
                    <div v-else class="w-1/3 bg-transparent h-10 flex items-center justify-center icon-step">
                        <i class="fas fa-tasks" style="color: white"></i>
                    </div>
                    <div class="w-2/3 bg-gray-200 h-12 flex flex-col items-center justify-center px-1 body-step">
                        <h2 class="font-bold text-sm">Revisión / Aprobación</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toRef } from 'vue'

export default {
    props: {
        currentStep: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const currentStep = toRef(props, 'currentStep');

        return { currentStep };
    },
    data() {
        return {

        }
    },

    methods: {
        changeStep(step) {
            this.$emit('changeStep', step)
        },
    }
}
</script>

<style scoped>.step {
    cursor: pointer;
}</style>