<template>
    <div>
        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>
        <div class="flex items-center justify-center w-full my-3">
            <label
                class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 ">
                <div class="flex flex-col items-center justify-center ">
                    <svg class="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Haga clic para
                            subir los archivos</span>
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG ó JPEG (MAX. 2MB)</p>
                    <span v-if="loading" class="spinner-border spinner-border-sm mb-" role="status"
                        aria-hidden="true"></span>
                </div>
                <input type="file" class="hidden" @change="previewFiles($event)" multiple accept=".jpg, .jpeg, .png" />
            </label>
        </div>
        <div class="mb-4 pt-5" style="max-height: 400px; overflow-y: auto;">
            <div v-for="(filesUrls, index) in currentData.filesUrls"
                class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
                <div class="md:col-span-2 flex items-center justify-center">
                    <div class="relative inline-block">
                        <div class="photo-stack w-24 h-24" @click="showImages(filesUrls)">
                            <img v-for="(image, index) in filesUrls.images" :src="image.url" class="w-24 h-24 rounded" />
                        </div>
                        <div @click="removeImage(index)"
                            class="absolute top-0 right-0 transform translate-x-1 -translate-y-1 cursor-pointer"
                            style="z-index: 111;">
                            <div
                                class="bg-red-500 rounded-full w-4 h-4 flex items-center justify-center group-hover:bg-red-600 transition-colors">
                                <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:col-span-8">
                    <div class="row">
                        <div class="col col-8">
                            <div class="text-left">
                                <label class="text-base">Título de las imágenes</label>
                            </div>
                            <input v-model="filesUrls.description" type="text"
                                class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                        <div class="col col-2">
                            <div class="text-left md:mt-0">
                                <label class="text-base">Ancho(px)</label>
                            </div>
                            <input v-model="filesUrls.width" type="number"
                                class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                        <div class="col col-2">
                            <div class="text-left">
                                <label class="text-base">Alto(px)</label>
                            </div>
                            <input v-model="filesUrls.height" type="number"
                                class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="images" v-viewer>
            <img v-for="src in images" :key="src" :src="src">
        </div>
        <div class="border-b border-gray-300 mb-3"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: "Anexos",
            currentData: {
                filesUrls: [
                ]
            },

            loading: false,
        }
    },
    mounted() {
        if (Object.entries(this.data).length == 0) {
            this.currentData = {
                filesUrls: []
            }
        }
        else {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),

        async uploadFiles(files) {
            let url = `${this.getApiUrl}/reports/upload-images`;
            let token = this.getUserData.token;

            let formData = new FormData();
            files.forEach(file => {
                formData.append('file[]', file);
            });

            let response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': token,
                },
            });

            return response.json();
        },
        async previewFiles(event) {
            this.loading = true;
            let fileList = event.target.files;
            let selectedFiles = Array.from(fileList).splice(0, 4);

            const maxSizeInBytes = 2 * 1024 * 1024;
            let imagesFitOnSize = selectedFiles.every(file => {
                return file.size <= maxSizeInBytes;
            });

            if (!imagesFitOnSize) {
                this.showMsg('error', 'Imagen', 'Un archivo es demasiado grande');
                this.loading = false;

                return;
            }

            let response = await this.uploadFiles(selectedFiles)
            if (response.success) {
                if (!response.imagesComplete) {
                    this.showMsg('warning', 'Imagen', 'Algunas imágenes no se subieron correctamente')
                }

                let imageUrls = [];
                response.imageUrls.forEach(fileUrl => {
                    imageUrls.push({
                        url: fileUrl,
                    });
                });
                this.currentData.filesUrls.push({
                    images: imageUrls,
                    description: '',
                    width: 300,
                    height: 300
                });
            } else {
                this.showMsg('error', 'Imagen', 'Error al subir los archivos');
            }

            this.loading = false;
        },
        async removeImage(index) {
            this.loading = true;
            let url = `${this.getApiUrl}/reports/delete-images`;
            let token = this.getUserData.token;
            let urls = this.currentData.filesUrls[index].images.map(image => image.url);
            let body = JSON.stringify({
                fileUrls: urls
            });

            let response = await fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            });
            response = await response.json();

            if (response.success) {
                this.currentData.filesUrls.splice(index, 1);
            } else {
                this.showMsg('error', 'Imagen', 'Error al eliminar el archivo');
            }
            this.loading = false;
        },
        showImages(fileUrls) {
            let images = fileUrls.images;
            images = images.map(image => image.url);
            this.$viewerApi({
                images: images,
            })
        },
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped>
.photo-stack {
    position: relative;
    margin: auto;
}

.photo-stack>p {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    font-weight: bold;
    z-index: 100;
}

.photo-stack img {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid white;
    box-shadow: 0 1px 3px -2px rgba(0, 0, 0, .5);
    transition: all 0.3s ease-out;
}

.photo-stack img:nth-child(1) {
    z-index: 99;
}

.photo-stack img:nth-child(2) {
    transform: rotate3d(0, 0, 1, 3deg);
}

.photo-stack img:nth-child(3) {
    transform: rotate3d(0, 0, 1, -3deg);
}

.photo-stack img:nth-child(4) {
    transform: rotate3d(0, 0, 1, 2deg);
}

.photo-stack:hover img:nth-child(1) {
    transform: scale(1.02);
}

.photo-stack:hover img:nth-child(2) {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
}

.photo-stack:hover img:nth-child(3) {
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -3deg);
}

.photo-stack:hover img:nth-child(4) {
    transform: translate3d(2%, -5%, 0) rotate3d(0, 0, 1, 2deg);
}

.photo-stack:hover img:nth-child(5) {
    transform: translate3d(-5%, -2%, 0) rotate3d(0, 0, 1, 2deg);
}
</style>
