<template>
    <div>
        <Navbar title="Configuración"></Navbar>
        <div class="bg-white border rounded mt-4 mx-20">
            <button @click="toggleAccordion(1)" class="flex justify-start  items-center p-4 focus:outline-none">
                <span class="ml-2" :class="{ 'underline': open == 1 }">Permiso de revisión de reportes</span>
            </button>
            <transition name="slide-down">
                <div v-if="open == 1" class="p-4 border-t grid gap-4 text-sm grid-cols-1 md:grid-cols-12">
                    <div class="md:col-span-6">
                        <div class="text-left">
                            <label class="text-base font-bold">Técnico</label>
                        </div>
                        <form class="flex items-center">
                            <label for="simple-search" class="sr-only">Buscar</label>
                            <div class="relative w-full">
                                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <div>
                                    <input v-model="technicianSearch" @input="handleSearch()" type="text"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Buscar el técnico..." required>
                                    <ul v-show="showSuggestions" ref="suggestionsList"
                                        class="bg-white border border-gray-300 mt-2 w-full rounded-lg shadow-lg absolute z-5"
                                        style="top: calc(100% + 8px);">
                                        <li v-for="suggestion in usersSuggestions" :key="suggestion.id"
                                            @click="selectSuggestion(suggestion)"
                                            class="py-2 px-4 cursor-pointer hover:bg-gray-100">
                                            <div class="text-left">
                                                <p>{{ suggestion.name + ' ' + suggestion.last_name }}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </transition>
        </div>

        <!-- <div class="mb-4 bg-white border rounded shadow mt-2 mx-20">
            <button @click="toggleAccordion(2)" class="flex justify-start  items-center p-4 focus:outline-none">
                <span class="ml-2" :class="{ 'underline': open == 2 }">Certificados de firma electrónica</span>
            </button>
            <transition name="slide-down">
                <div v-if="open == 2" class="p-4 border-t">
                </div>
            </transition>
        </div> -->
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import { mapGetters } from 'vuex';

export default {
    components: { Navbar },
    data() {
        return {
            open: 1,

            // Technician Search
            technicianSearch: '',
            showSuggestions: false,
            usersSuggestions: [],
            userSelected: null,
        };
    },
    mounted() {
        window.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        toggleAccordion(value) {
            if (this.open == value) {
                this.open = 0;
            }
            else {
                this.open = value;
            }
        },
        async handleSearch() {
            this.showSuggestions = this.technicianSearch.length >= 1;
            if (this.showSuggestions) {
                let url = `${this.getApiUrl}/users/technicians-suggestions/${this.technicianSearch}`;
                let token = this.getUserData.token;

                await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': token
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.usersSuggestions = data.users;
                    })
            }
        },
        selectSuggestion(suggestion) {
            this.technicianSearch = suggestion.name + ' ' + suggestion.last_name;
            this.userSelected = suggestion;
            this.showSuggestions = false;
        },
        handleClickOutside(event) {
            const suggestionsList = this.$refs.suggestionsList;
            if (suggestionsList && !this.$refs.suggestionsList.contains(event.target)) {
                this.showSuggestions = false;
            }
        },
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    }
}
</script>

<style scoped></style>