<template>
    <div>
        <Navbar title="Editar Informe">
            <template #default>
                <div v-if="currentStep == 3" class="flex items-center">
                    <button v-if="reportSuccessfullyGenerated" @click="downloadReport()"
                        class="flex items-center bg-blue-500 text-white px-3 py-2 rounded-md shadow-md hover:bg-blue-600 text-sm">
                        Descargar reporte
                    </button>
                    <button v-else-if="!loadingReportGeneration" @click="generateReport()"
                        class="flex items-center bg-blue-500 text-white px-3 py-2 rounded-md shadow-md hover:bg-blue-600 text-sm">
                        {{ loadingReportGeneration ? 'Generando' : 'Generar' }} reporte
                    </button>
                    <div v-else>
                        Generando reporte <i class="fas fa-spinner fa-spin ml-2"></i>
                    </div>
                </div>
            </template>
        </Navbar>
        <Stepper :currentStep="currentStep" @changeStep="changeStep"></Stepper>
        <div class="mt-4">
            <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 mx-4">
                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">
                    <GeneralReport v-if="currentStep == 1" @changeCurrentStep="changeStep"></GeneralReport>
                    <Activities v-if="currentStep == 2" @changeCurrentStep="changeStep"></Activities>
                    <ReportBody v-if="currentStep == 3" @changeCurrentStep="changeStep"></ReportBody>
                    <ReportReview v-if="currentStep == 4" @changeCurrentStep="changeStep"></ReportReview>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import Datatable from '../../components/Datatable';
import Activities from './Activities.vue';
import GeneralReport from './GeneralReport.vue';
import ReportBody from './ReportBody.vue';
import ReportReview from './ReportReview.vue';
import Stepper from './Stepper.vue';
import { mapGetters } from 'vuex';
import { ref } from 'vue';

export default {
    components: { Navbar, Datatable, Stepper, GeneralReport, Activities, ReportBody, ReportReview },
    data() {
        return {
            currentStep: 1,

            date: ref(new Date()),

            // Objectives
            generalObjective: '',
            specificObjectives: [
                ''
            ],

            stepsMapping: {
                'Registrado': 1,
                'En Planificación': 2,
                'En Progreso': 3,
                'En Revisión': 4,
                'Aprobado': 4
            },

            // Report Generation
            loadingReportGeneration: false,
            reportSuccessfullyGenerated: false,
            reportProcessingUrl: '',
            reportProcessingId: 0,
        }
    },
    mounted() {
        this.getReportStep();
    },
    methods: {
        generateReport() {
            this.loadingReportGeneration = true;
            this.reportSuccessfullyGenerated = false;
            let reportId = this.$route.params.reportId;
            let url = `${this.getApiUrl}/reports/generate-report/${reportId}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Application-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 404) {
                        this.loadingReportGeneration = false;
                        this.showMsg('error', 'Generar informe', 'No se ha encontrado el informe');
                    } else if (data.status == 500 || data.status == 400) {
                        this.loadingReportGeneration = false;
                        let errorMsg = 'Ha ocurrido un error en la generación del informe';
                        this.showMsg('error', 'Generar informe', errorMsg);
                    } else {
                        this.loadingReportGeneration = true;
                        this.reportProcessingId = data.reportProcessingId;
                        setTimeout(this.checkReportGeneration, 5000);
                    }
                })
        },

        checkReportGeneration() {
            let url = `${this.getApiUrl}/reports/check-report-generation/${this.reportProcessingId}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200 && data.reportStatus == 'completed') {
                        this.loadingReportGeneration = false;
                        this.reportSuccessfullyGenerated = true;
                        this.reportProcessingUrl = data.url;
                        this.showMsg('success', 'Generar informe', 'Informe generado correctamente');
                    }
                    else if (data.status == 200 && (data.reportStatus == 'pending' || data.reportStatus == 'in_progress')) {
                        this.loadingReportGeneration = true;
                        setTimeout(this.checkReportGeneration, 5000);
                    }
                    else if (data.status == 404) {
                        this.loadingReportGeneration = false;
                        this.showMsg('error', 'Generar informe', 'No se ha encontrado el informe');
                    }
                    else {
                        this.loadingReportGeneration = false;
                        this.reportSuccessfullyGenerated = false;
                        this.showMsg('error', 'Generar informe', 'Error al generar el informe');
                    } 
                });
        },

        downloadReport() {
            const link = document.createElement('a');
            link.href = this.reportProcessingUrl;
            link.setAttribute('target', '_blank');
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.reportProcessingUrl = '';
            this.loadingReportGeneration = false;
            this.reportSuccessfullyGenerated = false;
        },

        getReportStep() {
            this.loadingFinish = true;
            let reportId = this.$route.params.reportId;
            let url = `${this.getApiUrl}/reports/get-report-status/${reportId}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        let step = this.stepsMapping[data.reportStatus]
                        this.changeStep(step);
                    }
                });
        },
        changeStep(step) {
            this.currentStep = step;
        },
        addSpecificObjective() {
            this.specificObjectives.push("");
        },
        removeSpecificObjective(index) {
            this.specificObjectives.splice(index, 1);
        },
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    }
}
</script>

<style scoped>
.btn-save {
    font-family: 'Nunito Sans';
    background-color: #FFA500;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    ;
}

.btn-save:hover {
    background-color: #117bc3;
}

.btn-cancel {
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: normal;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
</style>