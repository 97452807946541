<template>
    <div>
        <Navbar title="Editar Usuario"></Navbar>
        <div class="mt-4">
            <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 mx-4">
                <div class="grid gap-4 text-sm grid-cols-1 lg:grid-cols-1">
                    <h2 class="text-base text-left font-semibold leading-7 text-gray-900 mx-20">Perfil del Usuario</h2>
                    <div class="lg:col-span-2 mx-20">
                        <div class="grid gap-x-10 gap-y-2 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-2">
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Nombre</label>
                                </div>
                                <input v-model="user.name" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Apellido</label>
                                </div>
                                <input v-model="user.last_name" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Email</label>
                                </div>
                                <input v-model="user.email" type="email"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                                <div class="text-left">
                                    <span v-if="user.email.length > 0 && !isValidEmail()" class="text-red-600">
                                        Email no válido
                                    </span>
                                </div>
                            </div>
                            <div class="md:col-span-5"></div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Empresa</label>
                                </div>
                                <input v-model="company.company_name" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" readonly/>
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Departamento</label>
                                </div>
                                <input v-model="user.department" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Número Telefónico</label>
                                </div>
                                <input v-model="user.phone_number" type="text"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none"
                                    placeholder="0999999999" />
                                <div class="text-left">
                                    <span v-if="user.phone_number.length > 0 && !isValidPhone()" class="text-red-600">
                                        Teléfono no válido
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lg:col-span-2 mx-20">
                        <div class="flex mt-3 items-center justify-start space-x-4">
                            <button @click="updateUser()" :disabled="loadingUpdate || !isValid"
                                :class="{ 'opacity-50 cursor-not-allowed': loadingUpdate || !isValid }"
                                class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                <span v-if="loadingUpdate" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                Actualizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="getUserData.role != 'customer'" class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 mx-4">
                <div class="grid gap-4 text-sm grid-cols-1 lg:grid-cols-1">
                    <h2 class="text-base text-left font-semibold leading-7 text-gray-900 mx-20">Certificado de firma digital
                    </h2>
                    <div v-show="user.digital_signature_certificate_url == null">
                        <div class="lg:col-span-2 mx-20">
                            <div class="grid gap-x-10 gap-y-2 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-2">
                                <div class="md:col-span-5">
                                    <div class="text-left">
                                        <label class="text-base font-bold">Seleccionar certificado:</label>
                                    </div>
                                    <input ref="digital_signature" class="mt-1 p-1 w-full resize-none" type="file"
                                        accept=".p12" />
                                </div>
                                <div class="md:col-span-5"></div>
                                <div class="md:col-span-5">
                                    <div class="text-left">
                                        <label class="text-base font-bold">Contraseña</label>
                                    </div>
                                    <input v-model="digitalSignaturePassword" type="password"
                                        class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                                </div>
                            </div>
                        </div>
                        <div class="lg:col-span-2 mx-20">
                            <div class="flex mt-3 items-center justify-start space-x-4">
                                <button @click="uploadDigitalSignature()" :disabled="loadingSignature"
                                    class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                    <span v-show="loadingSignature" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    Guardar certificado
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-show="user.digital_signature_certificate_url != null">
                        <div class="lg:col-span-2 mx-20">
                            <div class="flex items-center space-x-2">
                                <i class="fas fa-file-signature fa-lg"></i>
                                <span>{{ user.digital_signature_certificate_url ? getLastPart(user.digital_signature_certificate_url) : '' }}</span>
                            </div>
                        </div>
                        <div class="lg:col-span-2 mx-20">
                            <div class="flex mt-3 items-center justify-start space-x-4">
                                <button @click="deleteDigitalSignature()" :disabled="loadingSignatureDeletion"
                                    class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                    <span v-show="loadingSignatureDeletion" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    Olvidar certificado
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 mx-4">
                <div class="grid gap-4 text-sm grid-cols-1 lg:grid-cols-1">
                    <h2 class="text-base text-left font-semibold leading-7 text-gray-900 mx-20">Cambiar Contraseña</h2>
                    <div class="lg:col-span-2 mx-20">
                        <div class="grid gap-x-10 gap-y-2 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-2">
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Contraseña actual</label>
                                </div>
                                <input v-model="changePasswordData.currentPassword" type="password"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5"></div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Nueva contraseña</label>
                                </div>
                                <input v-model="changePasswordData.newPassword" type="password"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                            </div>
                            <div class="md:col-span-5">
                                <div class="text-left">
                                    <label class="text-base font-bold">Repite la nueva contraseña</label>
                                </div>
                                <input v-model="changePasswordData.newPasswordConfirmation" type="password"
                                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                                <div class="text-left">
                                    <span
                                        v-show="changePasswordData.newPassword.length > 0 && changePasswordData.newPassword != changePasswordData.newPasswordConfirmation"
                                        class="text-red-600">
                                        Las contraseñas no coinciden
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="lg:col-span-2 mx-20">
                        <div class="flex mt-3 items-center justify-start space-x-4">
                            <button @click="changePassword()" :disabled="loadingPasswordUpdate"
                                class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                <span v-if="loadingPasswordUpdate" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                Cambiar contraseña
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import { mapGetters } from 'vuex';

export default {
    components: {
        Navbar
    },
    data() {
        return {
            initialData: {},
            user: {
                id: 0,
                name: '',
                last_name: '',
                email: '',
                phone_number: '',
                company: '',
                department: '',
                role: '',
                digital_signature_certificate_url: null
            },
            company: {
                company_name: '',
                commercial_name: '',
                identification: ''
            },

            loadingUpdate: false,

            changePasswordData: {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirmation: ''
            },
            loadingPasswordUpdate: false,

            // Digital signature
            digitalSignaturePassword: '',
            loadingSignature: false,
            loadingSignatureDeletion: false

        }
    },
    mounted() {
        this.getUser();
    },
    methods: {
        getUser() {
            let token = this.getUserData.token;
            let url = `${this.getApiUrl}/users/profile/${token}`;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.user = data.user;
                    this.company = data.company;
                    this.initialData = data.user;
                })
        },
        deleteDigitalSignature() {
            this.loadingSignatureDeletion = true;
            let url = `${this.getApiUrl}/users/digital-signature-certificate`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.user.digital_signature_certificate_url = null;
                        this.digitalSignaturePassword = '';
                        this.showMsg('success', 'Certificado de firma digital', 'Certificado eliminado satisfactoriamente');
                    } else {
                        this.showMsg('error', 'Certificado de firma digital', 'Error al subir el archivo');
                    }
                })
                .finally(() => {
                    this.loadingSignatureDeletion = false;
                });
        },
        uploadDigitalSignature() {
            this.loadingSignature = true;
            let digitalSignatureInput = this.$refs.digital_signature;
            let file = digitalSignatureInput.files[0];

            if (file) {
                let url = `${this.getApiUrl}/users/digital-signature-certificate`;
                let token = this.getUserData.token;

                let formData = new FormData();
                formData.append('file', file);
                formData.append('password', this.digitalSignaturePassword);

                fetch(url, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Authorization': token,
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        if (!data.success) {
                            this.showMsg('error', 'Certificado de firma digital', 'Error al subir el archivo');
                        } else {
                            this.user.digital_signature_certificate_url = data.filename;
                        }
                    })
                    .finally(() => {
                        this.loadingSignature = false;
                    })
            }
            else {
                this.showMsg('error', 'Certificado de firma digital', 'No se ha elegido un archivo');
            }

            
        },
        getLastPart(text) {
            const parts = text.split('_');
            if (parts.length > 1) {
                return parts[parts.length - 1];
            } else {
                return text;
            }
        },
        changePassword() {
            this.loadingPasswordUpdate = true;

            let validations = [
                this.changePasswordData.currentPassword.length > 0,
                this.changePasswordData.newPassword.length > 0,
                this.changePasswordData.newPasswordConfirmation.length > 0,
                this.changePasswordData.newPassword == this.changePasswordData.newPasswordConfirmation
            ]

            if (validations.every(validation => validation)) {
                let url = `${this.getApiUrl}/users/change-password`;
                let token = this.getUserData.token;
                let body = JSON.stringify({
                    currentPassword: this.changePasswordData.currentPassword,
                    newPassword: this.changePasswordData.newPassword
                });

                fetch(url, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                    },

                })
                    .then(data => {
                        if (data.status == 200) {
                            setTimeout(() => {
                                this.showMsg('success', 'Editar Usuario', 'Contraseña actualizada');
                                this.loadingPasswordUpdate = false;
                                this.changePasswordData = {
                                    currentPassword: '',
                                    newPassword: '',
                                    newPasswordConfirmation: ''
                                }
                            }, 300)
                        } else if (data.status == 401) {
                            setTimeout(() => {
                                this.showMsg('error', 'Editar Usuario', 'La contraseña actual es errónea');
                                this.loadingPasswordUpdate = false;
                            }, 300)
                        } else {
                            setTimeout(() => {
                                this.showMsg('error', 'Editar Usuario', 'Error al actualizar la contraseña');
                                this.loadingPasswordUpdate = false;
                            }, 300)
                        }
                    });
            } else {
                this.showMsg('error', 'Editar Usuario', 'Revise los datos para actualizar la contraseña');
                this.loadingUpdate = false;
            }
        },
        updateUser() {
            this.loadingUpdate = true;
            let url = `${this.getApiUrl}/users/${this.user.id}`;
            let token = this.getUserData.token;
            let body = JSON.stringify(this.user);

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.showMsg('success', 'Editar Usuario', 'Usuario editado satisfactoriamente');
                            this.loadingUpdate = false;
                        }, 300)
                    }
                });

        },
        goBack() {
            this.$router.push({ name: 'UsersList' });
        },
        isValidEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(this.user.email);
        },
        isValidPhone() {
            const phoneNumberPattern = /^0\d{9}$/;
            return phoneNumberPattern.test(this.user.phone_number);
        },
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
        isValid() {
            let validations = [
                this.user.name.length != 0,
                this.user.last_name.length != 0,
                this.user.email.length != 0,
                this.user.role.length != 0,
                this.user.department.length != 0,
                this.isValidEmail(),
                this.isValidPhone()
            ]

            return validations.every(validation => validation);
        }
    }
}
</script>

