<template>
    <div class="overflow-hidden rounded-lg border border-gray-200 shadow-md m-5">
        <!-- Datatabe Toolbar -->
        <div class="mt-3 md:flex md:items-right md:justify-between">

            <div class="relative flex items-center ml-3 md:mt-0">
                <span class="absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </span>

                <input v-model="search" @input="onSearchChange()" type="text" placeholder="Buscar"
                    class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40">
            </div>
        </div>

        <!-- Datatable Body -->
        <div class="overflow-x-auto">
            <table class="w-full border-collapse bg-white text-left text-sm text-gray-500">
                <colgroup>
                    <col v-for="(column, index) in columns" :key="index" :style="{ width: column.width }">
                </colgroup>

                <thead class="bg-gray-50">
                    <tr>
                        <th v-for="column in columns" scope="col" class="px-6 py-4 font-bold text-gray-900"
                            style="font-family: 'Nunito Sans';">
                            {{ column.title }}
                        </th>
                    </tr>
                </thead>

                <tbody class="divide-y divide-gray-100 border-t border-gray-100">
                    <tr v-if="isLoading && data.length === 0">
                        <td :colspan="columns.length" class="py-10 text-center">
                            <div class="spinner">
                                <div class="spinner-content">
                                    <span class="spinner-border spinner-border-lg" role="status"
                                        aria-hidden="true"></span>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr v-if="data.length === 0 && !isLoading">
                        <td :colspan="columns.length" class="py-10 text-center">
                            <div class="flex flex-col items-center">
                                <img class="w-1/12" src="../assets/advertencia.gif">
                                <p class="font-bold py-4">No hay datos para mostrar</p>
                            </div>
                        </td>
                    </tr>
                    <tr class="hover:bg-gray-50" v-for="row in data">
                        <td v-for="(column, index) in columns" :key="index" class="px-6 py-4">
                            <template v-if="(typeof column.render === 'function')">
                                <div v-html="column.render(row)"></div>
                            </template>
                            <template v-else>
                                {{ row[column.data] }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Datatable Footer -->
        <div class="mt-2 mb-2 sm:flex sm:items-center sm:justify-between ">
            <div class="text-sm ml-3 text-gray-500 dark:text-gray-400">
                Página <span class="font-medium text-gray-700 dark:text-gray-100">{{ data.length != 0 ? currentPage : 0 }}
                    de {{ totalPages
                    }}</span>
            </div>

            <div class="flex items-center mr-3 gap-x-4 sm:mt-0">
                <a @click="goToPage(1)"
                    class="cursor-pointer flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
                    :class="{
                            'cursor-pointer bg-white': currentPage != 1,
                            'pointer-events-none cursor-not-allowed bg-gray-200': currentPage == 1
                        }">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 rtl:-scale-x-100">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>

                    <span>
                        Primera (1)
                    </span>
                </a>
                <a @click="previousPage()"
                    class="cursor-pointer flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
                    :class="{
                            'cursor-pointer bg-white': currentPage != 1,
                            'pointer-events-none cursor-not-allowed bg-gray-200': currentPage == 1
                        }">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 rtl:-scale-x-100">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>

                    <span>
                        Anterior
                    </span>
                </a>

                <a @click="nextPage()"
                    class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 border rounded-md sm:w-auto gap-x-2 hover:bg-gray-500 "
                    :class="{
                        'cursor-pointer bg-white': currentPage != totalPages,
                        'pointer-events-none cursor-not-allowed bg-gray-200': currentPage >= totalPages
                    }">
                    <span>
                        Siguiente
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 rtl:-scale-x-100">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                </a>
                <a @click="goToPage(totalPages)"
                    class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 border rounded-md sm:w-auto gap-x-2 hover:bg-gray-500 "
                    :class="{
                        'cursor-pointer bg-white': currentPage != totalPages,
                        'pointer-events-none cursor-not-allowed bg-gray-200': currentPage >= totalPages
                    }">
                    <span>
                        Última ({{ totalPages }})
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 rtl:-scale-x-100">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        columns: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            search: '',
            isLoading: true, // Initially, show the spinner
        }
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 600);
    },
    methods: {
        onSearchChange() {
            this.$emit('search-change', this.search);
        },
        nextPage() {
            this.$emit('next-page');
        },
        previousPage() {
            this.$emit('previous-page');
        },
        goToPage(page) {
            this.$emit('to-page', page);
        }
    }
}
</script>

<style scoped>
.btn-new {
    font-family: 'Nunito Sans';
    background-color: #FFA500;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    ;
}
</style>
