<template>
  <div>
    <h3 class="text-l font-semibold mb-4">{{ title }}</h3>
    <Line :data="chartData" :options="chartOptions"></Line>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  components: { Line },
  props: {
    datasets: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets.map(dataset => ({
          label: dataset.label,
          backgroundColor: dataset.backgroundColor || "rgba(75, 192, 192, 0.2)",
          borderColor: dataset.borderColor || "rgba(75, 192, 192, 1)",
          borderWidth: dataset.borderWidth || 1,
          data: dataset.data,
        })),
      }
    }
  }
};
</script>