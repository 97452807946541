<template>
    <div>
        <Navbar title="Datos Inedyc"></Navbar>
        <div class="mt-4">
            <!-- component -->
            <div class="container max-w-screen-lg mx-auto">
                <div>
                    <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">
                            <div class="lg:col-span-2">
                                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                    <div class="md:col-span-3">
                                        <label for="full_name">Nombre de la Compañía</label>
                                        <input v-model="company_name" type="text" name="full_name" id="full_name"
                                            class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>

                                    <div class="md:col-span-2">
                                        <label for="email">RUC</label>
                                        <input v-model="ruc" type="text" name="email" id="email"
                                            class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                    </div>

                                    <div class="md:col-span-3">
                                        <label for="address">Dirección</label>
                                        <input v-model="address" type="text" name="address" id="address"
                                            class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="" />
                                    </div>

                                    <div class="md:col-span-2">
                                        <label for="city">Teléfono</label>
                                        <input v-model="phone" type="text" name="city" id="city"
                                            class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="" />
                                    </div>

                                    <div class="md:col-span-5">
                                        <label for="country">Emails</label>
                                        <div class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                                            <input v-model="emails" id="emails" placeholder="acamacho@inedyc.com"
                                                class="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent" />
                                        </div>
                                    </div>

                                    <div class="md:col-span-5 text-left">
                                        <div class="inline-flex items-end">
                                            <button class="btn-save bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded mr-3"
                                                @click="updateContractorData()">
                                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Guardar
                                            </button>
                                            <button class="btn-cancel font-bold py-2 px-4 rounded"
                                                @click="cancelChanges()">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import { mapGetters } from 'vuex';

export default {
    components: { Navbar },
    data() {
        return {
            company_name: '',
            ruc: '',
            address: '',
            phone: '',
            emails: '',
            initialData: {},

            loading: false,
        }
    },
    mounted() {
        this.getContractorData();
    },
    methods: {
        getContractorData() {
            let url = `${this.getApiUrl}/contractor-data`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.payload) {
                        let contractorData = data.payload;
                        this.company_name = contractorData.company_name;
                        this.ruc = contractorData.ruc;
                        this.address = contractorData.address;
                        this.phone = contractorData.phone;
                        this.emails = contractorData.emails;
                        this.initialData = contractorData;
                    }
                });
        },
        cancelChanges() {
            this.company_name = this.initialData.company_name;
            this.ruc = this.initialData.ruc;
            this.address = this.initialData.address;
            this.phone = this.initialData.phone;
            this.emails = this.initialData.emails;
        },
        updateContractorData() {
            this.loading = true;
            let url = `${this.getApiUrl}/update-contractor-data`;
            let token = this.getUserData.token;
            let body = {
                company_name: this.company_name,
                ruc: this.ruc,
                address: this.address,
                phone: this.phone,
                emails: this.emails,
            };
            body = JSON.stringify(body)

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.showMsg('success', 'Edición de datos empresariales', 'Datos editados satisfactoriamente');
                            this.loading = false;
                        }, 300)
                    }
                    else {
                        setTimeout(() => {
                            this.showMsg('error', 'Edición de datos empresariales', 'Error al editar la información');
                            this.loading = false;
                        }, 300)
                    }
                })
                .catch((err) => {
                    setTimeout(() => {
                        this.showMsg('error', 'Edición de datos empresariales', 'Error al editar la información');
                        this.loading = false;
                    }, 300)
                });
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    }
}
</script>

<style scoped>
.btn-save {
    font-family: 'Nunito Sans';
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    ;
}

.btn-cancel {
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: normal;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
</style>