<template>
    <div class="lg:col-span-2">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Desarrollo del informe</h2>
        <p class="mt-1 leading-6 text-gray-600">En esta sección se desarrollará el informe en su totalidad, indicando
            las
            pruebas realizadas y los análisis correspondientes.</p>
        <div class="border-b border-gray-300 mt-3"></div>

        <div v-if="reportTables.length == 0" class="flex items-center justify-center w-full my-3">
            <label
                class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 ">
                <div class="flex flex-col items-center justify-center ">
                    <svg class="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Haga clic para
                            subir el informe</span>
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">Tipo permitido: PDF</p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">Al subir un informe en pdf no será permitido
                        agregar
                        tablas adicionales</p>
                    <span v-if="loadingReportUploading" class="spinner-border spinner-border-sm mb-" role="status"
                        aria-hidden="true"></span>
                </div>
                <input ref="fileInput" type="file" class="hidden" @change="previewFiles($event)"
                    accept="application/pdf" />
            </label>
        </div>

        <div v-if="reportTables.length == 0 && reportFile" class="pt-3" style="max-height: 400px; overflow-y: auto;">
            <div class="text-center">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Informe</h2>
            </div>
            <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
                <div class="md:col-span-2 flex items-center justify-center">
                    <div class="relative inline-block">
                        <img class="w-24 h-24 rounded" src="../../assets/pdf_file_icon.svg" />
                        <div @click="removeReport()"
                            class="absolute top-0 right-0 transform translate-x-1 -translate-y-1 cursor-pointer">
                            <div
                                class="bg-red-500 rounded-full w-4 h-4 flex items-center justify-center group-hover:bg-red-600 transition-colors">
                                <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:col-span-8">
                    <div class="text-left">
                        <label class="text-base">Nombre del archivo:</label>
                    </div>
                    <div class="text-left">
                        <label class="text-base">{{ reportFilename }}</label>
                    </div>
                    <div class="text-left mt-2">
                        <button @click="downloadFile(reportFile, reportFilename)"
                            class="cursor-pointer text-sm font-medium text-orange-600 hover:text-orange-500">Ver
                            archivo</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-4" v-for="(component, index) in reportTables" :key="index">
            <div class="flex justify-between">
                <div class="flex justify-start space-x-3">
                    <button v-if="index != 0" @click="putTableAbove(index)"
                        class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                        <i class="fas fa-arrow-up"></i>
                    </button>
                    <button v-if="index != reportTables.length - 1" @click="putTableBelow(index)"
                        class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                        <i class="fas fa-arrow-down"></i>
                    </button>
                </div>

                <button
                    class="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    @click="removeComponent(index)">
                    Eliminar
                </button>
            </div>
            <component :is="component.tableName" :index="index" :data="component.data" />
        </div>

        <div class="flex mt-5 items-center justify-between space-x-4">
            <div class="flex items-center justify-start space-x-4">
                <div class="relative">
                    <select v-model="selectedValue" :disabled="reportFile"
                        class="block appearance-none bg-white border border-gray-300 px-4 py-2 rounded leading-tight focus:outline-none focus:border-blue-500">
                        <option value="Selecciona una tabla..." disabled selected>Selecciona una tabla...</option>
                        <option class="w-100" v-for="item in options" :key="item.value" :value="item.value">{{ item.name
                            }}
                        </option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
                <button @click="addTable()" :disabled="selectedValue == 'Selecciona una tabla...' || reportFile"
                    :class="{ 'opacity-50 cursor-not-allowed': reportFile }"
                    class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Agregar
                </button>
            </div>
            <div class="flex items-center justify-end space-x-4">
                <button @click="saveReportBody()" :disabled="loadingSave"
                    :class="{ 'opacity-50 cursor-not-allowed': loadingSave }"
                    class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    <span v-if="loadingSave" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Guardar
                </button>
                <button @click="finishReportBody()" :disabled="loadingFinish"
                    :class="{ 'opacity-50 cursor-not-allowed': loadingFinish }"
                    class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    <span v-if="loadingFinish" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Siguiente
                </button>
            </div>
        </div>

        <ninja-keys @selected="selected" @change="change" :noAutoLoadMdIcons="false" :placeholder="placeholder"
            :data="hotkeys"></ninja-keys>
    </div>
</template>

<script>
import EquipmentInformation from './tables/EquipmentInformation.vue';
import TransformerRatio from './tables/TransformerRatio.vue';
import IsolationResistance from './tables/IsolationResistance.vue';
import OhmicResistanceTestOfWindings from './tables/OhmicResistanceTestOfWindings.vue';
import OhmicResistanceIsolation from './tables/OhmicResistanceIsolation.vue';
import DielectricOilDiagnostics from './tables/DielectricOilDiagnostics.vue';
import PowerFactorTest from './tables/PowerFactorTest.vue';
import PhotographicRecord from './tables/PhotographicRecord.vue';
import Heading from './tables/Heading.vue';
import Subheading from './tables/Subheading.vue';
import ResultsAnalysis from './tables/ResultsAnalysis.vue';
import Conclusions from './tables/Conclusions.vue';
import NewPage from './tables/NewPage.vue';
import OilThermofiltration from './tables/OilThermofiltration.vue';
import Recommendations from './tables/Recommendations.vue';
import Remarks from './tables/Remarks.vue';
import "ninja-keys";
import Paragraph from './tables/Paragraph.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';

export default {
    components: {
        EquipmentInformation, TransformerRatio,
        IsolationResistance, OhmicResistanceTestOfWindings,
        OhmicResistanceIsolation, DielectricOilDiagnostics,
        PowerFactorTest, PhotographicRecord, Heading,
        Subheading, ResultsAnalysis, Conclusions, Recommendations,
        Remarks, NewPage, OilThermofiltration, Paragraph
    },
    data() {
        return {
            // Add Table options
            selectedValue: "Selecciona una tabla...",
            options: [
                { value: { tableName: 'Heading', data: {} }, name: 'Título Principal' },
                { value: { tableName: 'Subheading', data: {} }, name: 'Título Secundario' },
                { value: { tableName: 'Paragraph', data: {} }, name: 'Párrafo' },
                { value: { tableName: 'EquipmentInformation', data: {} }, name: 'Información del equipo' },
                { value: { tableName: 'TransformerRatio', data: {} }, name: 'Relación de Transformación' },
                { value: { tableName: 'IsolationResistance', data: [] }, name: 'Resistencia de Aislamiento' },
                { value: { tableName: 'OhmicResistanceTestOfWindings', data: [] }, name: 'Ensayo de Resistencia Ohmica de Devanados' },
                { value: { tableName: 'OhmicResistanceIsolation', data: {} }, name: 'Aislamiento de Resistencia Óhmica' },
                { value: { tableName: 'DielectricOilDiagnostics', data: {} }, name: 'Diagnóstico de Aceite - Rigidez Dieléctrica' },
                { value: { tableName: 'PowerFactorTest', data: {} }, name: 'Ensayo de Factor de Potencia' },
                { value: { tableName: 'PhotographicRecord', data: {} }, name: 'Archivo Fotográfico' },
                { value: { tableName: 'ResultsAnalysis', data: {} }, name: 'Análisis de Resultados' },
                { value: { tableName: 'Conclusions', data: {} }, name: 'Conclusiones' },
                { value: { tableName: 'NewPage', data: {} }, name: 'Salto de página' },
                { value: { tableName: 'Recommendations', data: {} }, name: 'Recomendaciones' },
                { value: { tableName: 'Remarks', data: {} }, name: 'Observaciones' },
                { value: { tableName: 'OilThermofiltration', data: {} }, name: 'Termofiltrado de Aceite' }
            ],

            loadingSave: false,
            loadingFinish: false,

            // Report by PDF
            reportFilename: '',
            reportFile: null,
            loadingReportUploading: false,

            // Ninja Keys
            placeholder: "Escriba para buscar el nombre de la tabla",
            hotkeys: [
                { id: 'Heading', title: 'Título Principal', data: {} },
                { id: 'Subheading', title: 'Título Secundario', data: {} },
                { id: 'Paragraph', title: 'Párrafo', data: {} },
                { id: 'EquipmentInformation', title: 'Información del equipo', data: {} },
                { id: 'TransformerRatio', title: 'Relación de Transformación', data: {} },
                { id: 'IsolationResistance', title: 'Resistencia de Aislamiento', data: [] },
                { id: 'OhmicResistanceTestOfWindings', title: 'Ensayo de Resistencia Ohmica de Devanados', data: [] },
                { id: 'OhmicResistanceIsolation', title: 'Aislamiento de Resistencia Óhmica', data: {} },
                { id: 'DielectricOilDiagnostics', title: 'Diagnóstico de Aceite - Rigidez Dieléctrica', data: {} },
                { id: 'PowerFactorTest', title: 'Ensayo de Factor de Potencia', data: {} },
                { id: 'PhotographicRecord', title: 'Archivo Fotográfico', data: {} },
                { id: 'ResultsAnalysis', title: 'Análisis de Resultados', data: {} },
                { id: 'Conclusions', title: 'Conclusiones', data: {} },
                { id: 'NewPage', title: 'Salto de página', data: {} },
                { id: 'Recommendations', title: 'Recomendaciones', data: {} },
                { id: 'Remarks', title: 'Observaciones', data: {} },
                { id: 'OilThermofiltration', title: 'Termofiltrado de Aceite', data: {} }
            ]
        }
    },
    mounted() {
        this.getReportBody();
    },
    methods: {
        ...mapActions(['addReportTable', 'saveAllReportTable']),
        finishReportBody() {
            this.loadingFinish = true;
            let url = `${this.getApiUrl}/reports/finish-report-body`;
            let token = this.getUserData.token;
            let reportId = this.$route.params.reportId;
            let body = JSON.stringify({
                reportId: reportId,
                data: this.getReportTables
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.showMsg('success', 'Informes', 'Informe actualizado satisfactoriamente');
                            this.$emit('changeCurrentStep', 4);
                            this.loadingFinish = false;
                        }, 300)
                    }
                });
        },
        getReportBody() {
            let reportId = this.$route.params.reportId;
            let url = `${this.getApiUrl}/reports/get-report-body/${reportId}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        if (data.url) {
                            this.reportFile = data.url;
                            this.reportFilename = data.report_filename;
                            this.saveAllReportTable([])
                        }
                        else {
                            this.saveAllReportTable(data.data);
                        }
                    }
                });
        },
        saveReportBody() {
            this.loadingSave = true;
            let url = `${this.getApiUrl}/reports/save-report-body`;
            let token = this.getUserData.token;
            let reportId = this.$route.params.reportId;

            let body = JSON.stringify({
                reportId: reportId,
                data: this.getReportTables
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.showMsg('success', 'Informes', 'Informe actualizado satisfactoriamente');
                            this.loadingSave = false;
                        }, 300)
                    }
                });
        },
        addTable() {
            if (this.selectedValue != 'Selecciona una tabla...') {
                let componentId = uuidv4();
                let componentData = {
                    uuid: componentId,
                    tableName: this.selectedValue.tableName,
                    data: this.selectedValue.data
                };
                this.addReportTable(componentData);
            }
            this.selectedValue = 'Selecciona una tabla...';
        },
        removeComponent(index) {
            this.reportTables.splice(index, 1);
        },
        putTableAbove(index) {
            if (index > 0 && index < this.reportTables.length) {
                let reportTablesCopy = [...this.reportTables];
                const tableToMove = reportTablesCopy.splice(index, 1)[0];
                reportTablesCopy.splice(index - 1, 0, tableToMove)

                this.saveAllReportTable(reportTablesCopy);
            }
        },
        putTableBelow(index) {
            if (index >= 0 && index < this.reportTables.length - 1) {
                let reportTablesCopy = [...this.reportTables];
                const tableToMove = reportTablesCopy.splice(index, 1)[0];
                reportTablesCopy.splice(index + 1, 0, tableToMove)

                this.saveAllReportTable(reportTablesCopy);
            }
        },

        async uploadFile(file, filename) {
            let url = `${this.getApiUrl}/reports/upload-report-pdf`;
            let token = this.getUserData.token;

            let formData = new FormData();
            formData.append('file', file);
            formData.append('reportId', this.$route.params.reportId);
            formData.append('filename', filename);

            let response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': token,
                },
            })
                .then(response => {
                    return response.json();
                });

            return response;
        },
        async previewFiles(event) {
            this.loadingReportUploading = true;
            this.reportTables = [];
            let selectedFile = event.target.files[event.target.files.length - 1];
            let filename = selectedFile.name;

            let response = await this.uploadFile(selectedFile, filename)
            if (response.success) {
                this.reportFile = response.fileUrl;
                this.reportFilename = response.filename
            } else {
                this.showMsg('error', 'Informe', 'Error al subir el archivo');
            }

            this.$refs.fileInput.value = '';
            this.loadingReportUploading = false;
        },
        async removeReport() {
            this.loadingReportUploading = true;
            let url = `${this.getApiUrl}/reports/delete-report-pdf`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.$route.params.reportId
            });

            let response = await fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            });
            response = await response.json();

            if (response.success) {
                this.reportFile = null;
            } else {
                this.showMsg('error', 'Factura', 'Error al eliminar el archivo');
            }
            this.loadingReportUploading = false;
        },
        selected(event) {
            let tableName = event.detail.action.id;
            let data = { ...event.detail.action.data };
            let componentId = uuidv4();
            let componentData = {
                uuid: componentId,
                tableName: tableName,
                data: data
            };
            this.addReportTable(componentData);
            const ninja = document.querySelector('ninja-keys');
            ninja.close();
        },
        downloadFile(url, name) {
            saveAs(url, name);
        }
    },
    computed: {
        ...mapState(['reportTables']),
        ...mapGetters(['getApiUrl', 'getUserData', 'getReportTables']),
    },
}
</script>

<style scoped>
.sticky {
    position: sticky;
    top: 0;
    z-index: 100;
}

ninja-keys {
    --ninja-accent-color: #FFA500
}
</style>