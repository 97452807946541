<template>
    <div class="lg:col-span-2">
        <h4 class="text-base font-semibold leading-7 text-gray-900 mb-2">{{ title }}</h4>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Tipo de equipo</label>
                </div>
                <input v-model="currentData.equipmentType" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Serie del equipo</label>
                </div>
                <input v-model="currentData.equipmentSerie" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-5">
                <div class="text-center">
                    <label class="text-base font-bold">Ubicación</label>
                </div>
                <input v-model="currentData.location" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>

            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Marca</label>
                </div>
                <input v-model="currentData.brand" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Año</label>
                </div>
                <input v-model="currentData.year" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>

            <div class="md:col-span-6"></div>

            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Potencia</label>
                </div>
                <input v-model="currentData.power" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Voltaje</label>
                </div>
                <input v-model="currentData.voltage" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Intensidad</label>
                </div>
                <input v-model="currentData.intensity" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Frecuencia</label>
                </div>
                <input v-model="currentData.frequency" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Número de Fases</label>
                </div>
                <input v-model="currentData.phasesNumber" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Grupo de Conexión</label>
                </div>
                <input v-model="currentData.connectionGroup" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
            <div class="md:col-span-3">
                <div class="text-center">
                    <label class="text-base font-bold">Vol. Aceite:</label>
                </div>
                <input v-model="currentData.oilVolume" type="text"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none text-center" />
            </div>
        </div>

        <div class="my-2">
            <label class="text-base font-semibold leading-7 text-gray-900 block text-left">Descripción:</label>
            <div class="flex items-center justify-center">
                <input v-model="currentData.description" type="text" class="border mt-1 rounded p-2 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="border-b border-gray-300 mb-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Información del Equipo',
            currentData: {
                equipmentType: '',
                equipmentSerie: '',
                connectionGroup: '',
                phasesNumber: '',
                frequency: '',
                power: '',
                intensity: '',
                voltage: '',
                year: '',
                brand: '',
                location: '',
                oilVolume: '',
                description: ''
            }
        }
    },
    mounted() {
        if (Object.entries(this.data).length > 0) {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>