<template>
    <div class="lg:col-span-2">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Revisión del Informe</h2>
        <p class="mt-1 leading-6 text-gray-600">En esta sección se verifica la calidad y precisión de un informe antes de su
            distribución.</p>
        <div v-if="!isApproved" class="border-b border-gray-300 mt-3"></div>

        <div v-if="!isApproved" class="flex items-center justify-center w-full my-3">
            <label
                class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 ">
                <div class="flex flex-col items-center justify-center ">
                    <svg class="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Haga clic para
                            subir la factura</span>
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">PDF (MAX. 10MB)</p>
                    <span v-if="loadingReportInvoice" class="spinner-border spinner-border-sm mb-" role="status"
                        aria-hidden="true"></span>
                </div>
                <input ref="fileInput" type="file" class="hidden" @change="previewFiles($event)"
                    accept="application/pdf" />
            </label>
        </div>
        <div v-if="reportInvoice" class="pt-3" style="max-height: 400px; overflow-y: auto;">
            <div v-if="isApproved" class="text-center">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Factura</h2>
            </div>
            <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
                <div class="md:col-span-2 flex items-center justify-center">
                    <div class="relative inline-block">
                        <img class="w-24 h-24 rounded" src="../../assets/pdf_file_icon.svg" />
                        <div v-if="!isApproved" @click="removeInvoice()"
                            class="absolute top-0 right-0 transform translate-x-1 -translate-y-1 cursor-pointer">
                            <div
                                class="bg-red-500 rounded-full w-4 h-4 flex items-center justify-center group-hover:bg-red-600 transition-colors">
                                <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:col-span-8">
                    <div class="text-left">
                        <label class="text-base">Nombre del archivo:</label>
                    </div>
                    <div class="text-left">
                        <label class="text-base">{{ reportInvoiceFilename }}</label>
                    </div>
                    <div class="text-left mt-2">
                        <button @click="downloadFile(reportInvoice, reportInvoiceFilename)"
                            class="cursor-pointer text-sm font-medium text-orange-600 hover:text-orange-500">Ver archivo</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="border-b border-gray-300 mt-3"></div>

        <div
            class="w-90 mx-auto mt-3 border rounded-lg shadow-md bg-gray-100 p-4 relative flex items-center justify-center">
            <input v-model="approved" :disabled="isApproved" type="checkbox" class="mr-2 text-indigo-600 h-6 w-6">
            <p class="flex-grow px-5 text-gray-700">Por la presente, declaro que he revisado exhaustivamente el contenido
                del informe y confirmo que todos los datos y la información contenida en el mismo son precisos y completos
                según mi leal saber y entender. Asumo total responsabilidad por la exactitud y veracidad de este informe.
            </p>
        </div>
        <div class="w-90 mx-auto p-4 relative flex items-center justify-center">
            <button v-if="!isApproved" @click="approveReport()" :disabled="loadingApproval"
                :class="{ 'opacity-50 cursor-not-allowed': loadingApproval }"
                class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <span v-if="loadingApproval" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                {{ getUserData && getUserData.role == 'superadmin' ? 'Aprobar Informe' : 'Aprobar Revisión' }}
            </button>
            <span v-else class="bg-green-100 border border-green-300 text-green-600 px-2 py-1 rounded">
                {{ (getUserData && getUserData.role == 'superadmin' 
                    ? 'El informe ya ha sido aprobado por usted' 
                    : 'El informe ya ha sido revisado') }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {

    },
    data() {
        return {
            isApproved: false,
            approved: false,
            loadingApproval: false,

            // Report invoice
            reportInvoice: null,
            reportInvoiceFilename: null,
            loadingReportInvoice: false,
        }
    },
    mounted() {
        this.getReportApprovalInfo();
    },
    methods: {
        getReportApprovalInfo() {
            let reportId = this.$route.params.reportId;
            let url = `${this.getApiUrl}/reports/approval-info/${reportId}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        this.approved = data.approved;
                        this.isApproved = data.approved;
                        this.reportInvoice = data.invoice_url;
                        this.reportInvoiceFilename = data.invoice_filename;
                    }
                });
        },
        approveReport() {
            if (!this.approved) {
                this.showMsg('error', 'Informes', 'Es necesario aprobar la declaración juramentada');
                return;
            }

            this.loadingApproval = true;
            let url = `${this.getApiUrl}/reports/approve-report`;
            let token = this.getUserData.token;
            let reportId = this.$route.params.reportId;
            let body = JSON.stringify({
                reportId: reportId
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.showMsg('success', 'Informes', 'Informe actualizado satisfactoriamente');
                            this.loadingApproval = false;
                            this.isApproved = true;
                        }, 300)
                    } else {
                        setTimeout(() => {
                            this.showMsg('error', 'Informes', data.message);
                            this.loadingApproval = false;
                            this.isApproved = false;
                        }, 300)
                    }
                });
        },
        async uploadFile(file, filename) {
            let url = `${this.getApiUrl}/reports/upload-invoice`;
            let token = this.getUserData.token;

            let formData = new FormData();
            formData.append('file', file);
            formData.append('reportId', this.$route.params.reportId);
            formData.append('filename', filename);

            let response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': token,
                },
            })
                .then(response => {
					return response.json();
				});

            return response;
        },
        async previewFiles(event) {
            this.loadingReportInvoice = true;
            let selectedFile = event.target.files[event.target.files.length - 1];
            let filename = selectedFile.name;

            const maxSizeInBytes = 10 * 1024 * 1024;
            if (selectedFile.size > maxSizeInBytes) {
                this.showMsg('error', 'Factura', 'Archivo muy largo');
                this.loadingReportInvoice = false;

                return;
            }

            let response = await this.uploadFile(selectedFile, filename)
            if (response.success) {
                this.reportInvoice = response.fileUrl;
                this.reportInvoiceFilename = response.filename;
            } else {
                this.showMsg('error', 'Factura', 'Error al subir el archivo');
            }

            this.$refs.fileInput.value = '';
            this.loadingReportInvoice = false;
        },
        async removeInvoice() {
            this.loadingReportInvoice = true;
            let url = `${this.getApiUrl}/reports/delete-invoice`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.$route.params.reportId
            });

            let response = await fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            });
            response = await response.json();

            if (response.success) {
                this.reportInvoice = null;
            } else {
                this.showMsg('error', 'Factura', 'Error al eliminar el archivo');
            }
            this.loadingReportInvoice = false;
        },
        async downloadFile(url, name) {
            saveAs(url, name);
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),

        reportName() {
            if(this.reportInvoice) {
                return decodeURIComponent(this.reportInvoice.split('/').pop());
            }

            return '';
        }
    },
}
</script>

<style scoped></style>