<template>
    <div class="flex flex-col items-end">
        <div class="relative inline-block mt-auto">
            <select v-model="selected" id="period-select"
                class="text-left text-base block appearance-none w-64 bg-white border border-gray-300 hover:border-gray-400 px-4 py-1 rounded shadow leading-tight focus:outline-none focus:border-blue-500 focus:ring">
                <option>Este mes</option>
                <option>Mes anterior</option>
                <option>Año anterior</option>
                <option>Último año</option>
            </select>

            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    components: {
    },
    props: ['value'],
    data() {
        return {
            selected: 'Este mes'
        };
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        selected(newValue) {
            this.$emit("interval-change", newValue);
        }
    },
    created() {
    }
};
</script>
    
<style></style>
    