<template>
    <div class="w-full">
        <h1 class="text-base font-bold leading-7 text-gray-900 mb-3">{{ title }}</h1>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Salto de página',
        }
    },
    mounted() {},
    methods: {
        ...mapActions(['saveReportTable']),
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>