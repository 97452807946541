<template>
    <div>
        <Navbar title="Empresas">
            <template #default>
                <button @click="createCompany()" class="bg-orange-500 text-white px-3 py-2 rounded-md shadow-md hover:bg-orange-600 text-sm">
                    Crear Empresa
                </button>
            </template>
        </Navbar>
        
        <div class="mt-4">
            <Datatable :columns="columns" :data="data" :totalPages="totalPages" :currentPage="currentPage"
                @next-page="nextPage()" @previous-page="previousPage()" @to-page="goToPage" @search-change="searchChange"></Datatable>
        </div>

        <modal v-show="showDeleteModalCompany" title="Eliminar Empresa"
            description="¿Estás seguro de que quieres eliminar la empresa?">
            <template #buttons>
                <button @click="deleteCompany()" type="button"
                    class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 mx-3">
                    <span v-if="loadingDeletion" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Eliminar
                </button>
                <button @click="showDeleteModalCompany = false" type="button"
                    class="cancel-delete-company rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Cancelar
                </button>
            </template>
        </modal>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import Datatable from '../../components/Datatable';
import Modal from '../../components/Modal.vue'
import $ from 'jquery';
import { mapGetters } from 'vuex';

export default {
    components: { Navbar, Datatable, Modal },
    data() {
        return {
            // Delete modal User
            showDeleteModalCompany: false,
            selected: 'all',

            columns: [
                {
                    data: 'company_name',
                    title: 'Nombre',
                    width: '30%',
                    render: (row) => {
                        return (
                            `<div class="flex gap-3 px-6 py-2 font-normal text-gray-900">
                                <div class="text-sm w-full">
                                    <div class="font-medium text-gray-700">${row.company_name}</div>
                                    <div class="text-gray-400">${row.email}</div>
                                </div>
                            </div>`
                        )
                    }
                },
                {
                    data: 'identification',
                    title: 'Identificación',
                },
                {
                    data: 'id',
                    title: 'Teléfono',
                    render: (row) => {
                        if (row.regular_phone && row.regular_phone != '') {
                            return row.regular_phone;
                        }
                        return row.cell_phone;
                    }
                },
                {
                    data: 'contact_type',
                    title: 'Tipo de Contacto'
                },
                {
                    data: 'id',
                    title: 'Acciones',
                    render: (row) => {
                        return (
                            `<div class="flex gap-4">
                                <a class="edit-company cursor-pointer" data-id="${row.id}">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" class="h-6 w-6" x-tooltip="tooltip">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </a>
                                <a class="delete-company cursor-pointer" data-id="${row.id}">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" class="h-6 w-6" x-tooltip="tooltip">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </a>
                            </div>`
                        )
                    }
                }
            ],
            data: [],

            // Paging data
            search: '',
            currentPage: 1,
            totalPages: 0,
            limit: 10,

            // User to delete
            companyToDelete: 0,
            loadingDeletion: false
        }
    },
    mounted() {
        this.getCompanies();

        this.$nextTick(() => {
            $(document).on("click", ".edit-company", event => {
                let companyId = $(event.currentTarget).data("id");
                this.$router.push({ name: 'EditCompany', params: { companyId } });
            });

            $(document).on("click", ".delete-company", event => {
                this.companyToDelete = $(event.currentTarget).data("id");
                this.showDeleteModalCompany = true;
            });

            $(document).on("click", ".cancel-delete-company", event => {
                this.showDeleteModalCompany = false;
            });
        })
    },
    methods: {
        getCompanies() {
            let url = `${this.getApiUrl}/company/list?page=${this.currentPage}&limit=${this.limit}&search=${this.search}&role=${this.selected}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.data = data.companies;
                    this.totalPages = data.totalPages
                })
        },
        transformDateTime(dateTimeString) {
            let dateObj = new Date(dateTimeString);
            let year = dateObj.getFullYear();
            let month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            let day = dateObj.getDate().toString().padStart(2, '0');
            let hours = dateObj.getHours().toString().padStart(2, '0');
            let minutes = dateObj.getMinutes().toString().padStart(2, '0');

            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
        },
        createCompany() {
            this.$router.push({ name: 'CreateCompany'});
        },
        searchChange(searchValue) {
            this.currentPage = 1;
            this.search = searchValue;
            console.log(this.search);
            this.getCompanies();
        },
        nextPage() {
            this.currentPage += 1;
            this.getCompanies();
        },
        previousPage() {
            this.currentPage -= 1;
            this.getCompanies();
        },
        goToPage(page) {
            this.currentPage = page;
            this.getCompanies();
        },
        deleteCompany() {
            this.loadingDeletion = true;
            let url = `${this.getApiUrl}/company/${this.companyToDelete}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
                .then(data => {
                    if (data.status == 200) {
                        setTimeout(() => {
                            this.loadingDeletion = false;
                            this.showDeleteModalCompany = false;
                            this.getCompanies();
                        }, 300)
                    }

                })
                .catch(() => {
                    setTimeout(() => {
                        this.loadingDeletion = false;
                        this.showDeleteModalCompany = false;
                    }, 300)
                });
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
    }
}
</script>