<template>
    <section class="vh-100">
        <div class="container-fluid h-custom">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="../../assets/logo.png" class="img-fluid" alt="Sample image">
                </div>
                <div class="card box col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <!-- Te enviamos un correo con instrucciones para restablecer tu contraseña. -->
                    <div class="card-body">
                        <div class="my-5 ">
                            <div>
                                <label class="main-label mb-4">Restablece tu contraseña</label>
                                <p>Escribe tu correo electrónico y te enviaremos información para que puedas volver a
                                    acceder a tu cuenta.</p>
                                <input v-model="email" type="email" class="form-control form-control my-4" placeholder="Escribe tu email" />
                            </div>

                            <div class="text-lg my-4 pt-2">
                                <button @click="generatePasswordReset" class="btn btn-primary text-white" :disabled="!isValidEmail">
                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Generar correo de restablecimiento
                                </button>
                            </div>
                            <div>
                                <a href="#" class="link-primary">Iniciar sesión</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'SignIn',
    data() {
        return {
            alreadyTried: false,
            loading: false,
            email: ''
        }
    },
    methods: {
        generatePasswordReset() {
            this.loading = true;

            let url = `${this.getApiUrl}/users/forgot-password`;
            let body = {
                email: this.email,
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => {
					return response.json();
				})
                .then(data => {
                    if (data.status == 200) {
                        this.showMsg('success', 'Actualizar contraseña', 'Correo de restablecimiento generado');

                        this.$router.push({ name: 'SignIn' });
                    } else {
                        this.showMsg('error', 'Actualizar contraseña', 'Usuario no encontrado');
                    }
                    this.loading = false
                });
        }
    },
    computed: {
        ...mapGetters(['getApiUrl']),
        isValidEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(this.email);
        }
    }
}
</script>

<style scoped>
.main-label {
    font-size: 1.5rem;
    font-weight: 600
}

p {
    font-weight: 400
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.h-custom {
    height: calc(100% - 73px);
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}

.box:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-45deg, #c6f8ff 0%, #595cff 100% );
    transform: translate3d(0px, 20px, 0) scale(0.95);
    filter: blur(20px);
    opacity: var(0.7);
    transition: opacity 0.3s;
    border-radius: inherit;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.box::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
}
        

</style>
  