<template>
    <div class="w-full">
        <h1 class="text-base font-bold leading-7 text-gray-900 mb-3">{{ title }}</h1>

        <div class="grid mt-3">
            <div class="max-h-[15rem] overflow-y-auto">
                <div v-for="(remarks, index) in currentData.rows" :key="index" class="flex mb-2">
                    <textarea v-model="currentData.rows[index]"
                        class="h-15 border mt-1 rounded p-2 w-full bg-gray-50 resize-none" rows="2"></textarea>

                    <button @click="removeRemark(index)"
                        class="ml-2 mt-1 px-4 py-1 bg-red-500 text-white rounded">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </div>
            <div class="text-left mt-3">
                <button @click="addRemark()" class="px-4 py-2 bg-green-500 text-left text-white rounded">Agregar</button>
            </div>
        </div>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentData: {
                rows: [
                    ''
                ]
            },
            title: 'Observaciones'
        }
    },
    mounted() {
        if (Object.entries(this.data).length == 0) {
            this.currentData = {
                rows: [
                    ''
                ]
            }
        }
        else {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
        addRemark() {
            this.currentData.rows.push('');
        },
        removeRemark(index) {
            this.currentData.rows.splice(index, 1);
        },
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>