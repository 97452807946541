<template>
    <div>
        <Navbar title="Nuevo Informe">
        </Navbar>
        <div class="mt-4">
            <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6 mx-4">
                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-1">
                    <GeneralReport v-if="currentStep == 1"></GeneralReport>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import Datatable from '../../components/Datatable';
import Activities from './Activities.vue';
import GeneralReport from './GeneralReport.vue';
import ReportBody from './ReportBody.vue';
import Stepper from './Stepper.vue';

export default {
    components: { Navbar, Datatable, Stepper, GeneralReport, Activities, ReportBody },
    data() {
        return {
            currentStep: 1,
        }
    },
    methods: {
        changeStep(step) {
            this.currentStep = step;
        },
    }
}
</script>

<style scoped>
.btn-save {
    font-family: 'Nunito Sans';
    background-color: #FFA500;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    ;
}

.btn-save:hover {
    background-color: #117bc3;
}

.btn-cancel {
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: normal;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
</style>