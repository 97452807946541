<template>
    <form class="flex items-center">
        <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
            </div>
            <div>
                <button @click.prevent.stop="handleButtonClick" :disabled="disabled"
                    class="bg-gray-50 text-left border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Buscar clientes" required>Clientes seleccionados ({{ selectedOptions.length }}) </button>
                <ul v-show="showOptions" ref="multiselectList"
                    class="bg-white border border-gray-300 mt-2 w-full rounded-lg shadow-lg absolute z-3"
                    style="top: calc(100% + 8px); max-height: 250px; overflow-y: auto;">
                    <li v-if="loading" class="py-2 px-4 hover:bg-gray-100 text-center">
                        <i class="fas fa-spinner fa-spin text-4xl text-gray-500"></i>
                    </li>
                    <li v-if="options.length == 0" class="py-2 px-4 hover:bg-gray-100 text-center">
                        {{ noOptionsText }}
                    </li>
                    <li v-else v-for="option in options" :key="option.id" @click="selectSuggestion(option)"
                        class="py-2 px-4 cursor-pointer hover:bg-gray-100 text-left flex items-center">
                        <div class="flex gap-3 px-1 py-0 font-normal text-gray-900">
                            <div class="text-sm w-full">
                                <div class="font-medium text-gray-700">{{ option.name }} {{ option.last_name }}
                                </div>
                                <div class="text-gray-400">{{ option.email }}</div>
                            </div>
                        </div>
                        <div v-if="selectedOptions.includes(option.id)" class="ml-auto text-orange-500">
                            <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        noOptionsText: {
            type: String,
            default: 'No hay opciones disponibles'
        },
        initialOptionsSelected: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedOptions: [],
            showOptions: false,
        }
    },
    mounted() {
        window.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        selectSuggestion(suggestion) {
            let elementAlreadySelected = this.selectedOptions.includes(suggestion.id);

            if (!elementAlreadySelected) {
                this.selectedOptions.push(suggestion.id);
            } else {
                this.selectedOptions = this.selectedOptions.filter((option) => option !== suggestion.id);
            }
        },
        handleButtonClick() {
            this.showOptions = !this.showOptions;
        },
        handleClickOutside(event) {
            const multiselectList = this.$refs.multiselectList;
            if (multiselectList && !this.$refs.multiselectList.contains(event.target)) {
                this.showOptions = false;
            }

            this.$emit('onSelectedOptions', this.selectedOptions);
        },
    },
    watch: {
        initialOptionsSelected() {
            this.selectedOptions = this.initialOptionsSelected;
        }
    }
}
</script>

<style scoped></style>