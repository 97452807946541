<template>
    <div class="sidebar" :style="{ width: sidebarWidth }">

        <SidebarHeader />

        <div class="nav-divider no-margin" style="background-color: #3e5771; height: 1px;"></div>

        <SidebarLink v-if="getUserData && getUserData.role == 'superadmin'" to="/panel" icon="fas fa-columns" name="Dashboard">Dashboard</SidebarLink>
        <SidebarLink to="/panel/report-list" icon="fab fa-wpforms fa-lg" name="Informes">Informes</SidebarLink>
        <SidebarLink v-if="getUserData && getUserData.role != 'customer'" to="/panel/company-list" icon="fas fa-building fa-lg" name="Empresas">Empresas</SidebarLink>
        <SidebarLink v-if="getUserData && getUserData.role != 'customer'" to="/panel/users-list" icon="fas fa-users" name="Usuarios">Usuarios</SidebarLink>
        <div class="nav-divider no-margin" style="background-color: #3e5771; height: 1px;"></div>
        <SidebarLink to="/panel/personal-profile" icon="fas fa-id-card" name="Perfil Personal">Perfil Personal</SidebarLink>
        <!-- <SidebarLink v-if="getUserData && getUserData.role == 'superadmin'" to="/panel/settings" icon="fas fa-cog fa-lg" name="Configuración">Configuración</SidebarLink> -->
        <SidebarLink v-if="getUserData && getUserData.role == 'superadmin'" to="/panel/contractor" icon="fa fa-store-alt" name="Inedyc">Inedyc</SidebarLink>

    </div>
</template>

<script>
import SidebarLink from './SidebarLink'
import SidebarHeader from './SidebarHeader'
import { collapsed, toggleSidebar, sidebarWidth } from './state'
import { mapGetters } from 'vuex'

export default {
    props: {},
    components: { SidebarLink, SidebarHeader },
    setup() {
        return { collapsed, toggleSidebar, sidebarWidth }
    },
    computed: {
        ...mapGetters(['getUserData']),
    }
}
</script>

<style>
:root {
    --sidebar-bg-color: #2C3D4F;
    --sidebar-item-hover: #1D2A36;
    --sidebar-item-hover-boder: #a96e00;
    --sidebar-item-active-border: #FFA500;
    --sidebar-item-active: #1D2A36;
}
</style>

<style scoped>
.sidebar {
    color: white;
    background-color: var(--sidebar-bg-color);

    float: left;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    bottom: 0;

    transition: 0.3s ease;

    display: flex;
    flex-direction: column;
}

.sidebar h1 {
    height: 2.5em;
}

.logo {
    width: 90%;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: 0.2s linear;
}
</style>