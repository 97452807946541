<template>
    <div class="lg:col-span-2">
        <h1 class="text-base font-bold leading-7 text-gray-900 mb-3">{{ title }}</h1>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-4">
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Equipo de Medición Usado</label>
                </div>
                <input type="text" v-model="currentData.usedMeasuringEquipment"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Modelo</label>
                </div>
                <input type="text" v-model="currentData.model"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Marca</label>
                </div>
                <input type="text" v-model="currentData.brand"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Humedad Relativa</label>
                </div>
                <input type="text" v-model="currentData.relativeHumidity"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>
            <div class="md:col-span-4">
                <div class="text-center">
                    <label class="text-base font-bold">Temperatura</label>
                </div>
                <input type="text" v-model="currentData.temperature"
                    class="border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
            </div>

        </div>

        <div class="grid gap-x-10 gap-y-3 text-sm grid-cols-1 grid-flow-row md:grid-cols-12">
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">Ensayo</label>
                </div>
            </div>
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">AT-BT</label>
                </div>
            </div>
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">AT-GND</label>
                </div>
            </div>
            <div class="md:col-span-3">
                <div class="flex items-center justify-center h-full">
                    <label class="text-base font-bold">BT-GND</label>
                </div>
            </div>
        </div>

        <div class="max-h-[13rem] overflow-y-auto">
            <div v-for="value in currentData.rows"
                class="grid gap-x-5 gap-y-1 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mb-1">
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.test" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.at_bt" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.at_gnd" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
                <div class="md:col-span-3">
                    <div class="flex items-center justify-center h-full">
                        <input v-model="value.bt_gnd" type="text"
                            class="text-center border mt-1 rounded p-1 w-full bg-gray-50 resize-none" />
                    </div>
                </div>
            </div>
        </div>


        <div class="flex mt-3 items-center justify-end space-x-4">
            <button @click="addRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-plus"></i>
            </button>
            <button @click="removeRow()"
                class="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline">
                <i class="fas fa-minus"></i>
            </button>
        </div>

        <div class="my-2">
            <label class="text-base font-semibold leading-7 text-gray-900 block text-left">Descripción:</label>
            <div class="flex items-center justify-center">
                <input v-model="currentData.description" type="text"
                    class="border mt-1 rounded p-2 w-full bg-gray-50 resize-none" />
            </div>
        </div>

        <div class="border-b border-gray-300 my-3"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            title: 'Aislamiento de resistencia óhmica',
            currentData: {
                usedMeasuringEquipment: '',
                model: '',
                brand: '',
                relativeHumidity: '',
                temperature: '',
                rows: [{
                    test: '',
                    at_bt: '',
                    at_gnd: '',
                    bt_gnd: ''
                }],
                description: ''
            }
        }
    },
    mounted() {
        if (Object.entries(this.data).length == 0) {
            this.currentData = {
                usedMeasuringEquipment: '',
                model: '',
                brand: '',
                relativeHumidity: '',
                temperature: '',
                rows: [{
                    test: '',
                    at_bt: '',
                    at_gnd: '',
                    bt_gnd: ''
                }],
                description: ''
            }
        }
        else {
            this.currentData = this.data;
        }
    },
    methods: {
        ...mapActions(['saveReportTable']),
        addRow() {
            this.currentData.rows.push({
                test: '',
                at_bt: '',
                at_gnd: '',
                bt_gnd: ''
            })
        },
        removeRow() {
            this.currentData.rows.pop()
        }
    },
    watch: {
        currentData: {
            deep: true,
            handler(newValue) {
                this.saveReportTable({ index: this.index, newData: newValue });
            },
        }
    }
}
</script>

<style scoped></style>