<template>
    <section class="vh-100">
        <div class="container-fluid h-custom">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="../../assets/logo.png" class="img-fluid" alt="Sample image">
                </div>
                <div class="card box col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <div class="card-body">
                        <div class="my-5 ">
                            <div>
                                <label class="main-label mb-4">Restablece tu contraseña</label>
                                <div>
                                    <p style="display: inline;">Asegúrate de que tu nueva contraseña cumpla con nuestras
                                        políticas de seguridad </p>
                                    <span type="button" data-bs-toggle="tooltip" data-bs-placement="right"
                                        title="Tu contraseña debe tener al menos 8 caracteres, una minúscula, una mayúscula, un número y no contener espacios en blanco">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="currentColor"
                                            class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                                        </svg>
                                    </span>
                                </div>
                                <input v-model="newPassword" type="password" class="form-control form-control my-4"
                                    placeholder="Nueva contraseña" />
                                <input v-model="newPasswordConfirmation" type="password" class="form-control form-control mt-4"
                                    placeholder="Confirma tu nueva contraseña" />
                                <div v-if="newPassword.length > 0 && newPassword != newPasswordConfirmation" class="text-left">
                                    <span class="text-left text-red-500">Las contraseñas no coinciden</span>
                                </div>
                            </div>

                            <div class="text-lg my-4 pt-2">
                                <button @click="resetPassword()" class="btn btn-primary text-white" :disabled="!validNewPasswords">
                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    Restablecer contraseña
                                </button>
                            </div>
                            <div>
                                <a href="#" class="link-primary">Cancelar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import { Tooltip } from 'bootstrap';
import { mapGetters } from 'vuex';

export default {
    name: 'SignIn',
    data() {
        return {
            newPassword: '',
            newPasswordConfirmation: '',
            loading: false
        }
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    methods: {
        resetPassword() {
            this.loading = true;
            let token = this.$route.params.token;
            let url = `${this.getApiUrl}/users/reset-password`;
            let body = {
                token: token,
                newPassword: this.newPassword
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => {
					return response.json();
				})
                .then(data => {
                    if (data.status == 200) {
                        this.showMsg('success', 'Actualizar contraseña', 'Contraseña actualizada correctamente');

                        this.$router.push({ name: 'SignIn' });
                    } else {
                        this.showMsg('error', 'Actualizar contraseña', 'Error al actualizar la contraseña');
                    }
                    this.loading = false
                });

        }
    },
    computed: {
        ...mapGetters(['getApiUrl']),
        validNewPasswords() {
            return this.newPassword.length > 0 && this.newPassword == this.newPasswordConfirmation;
        }
    }
}
</script>

<style scoped>
.main-label {
    font-size: 1.5rem;
    font-weight: 600
}

p {
    font-weight: 400
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.h-custom {
    height: calc(100% - 73px);
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}

.box:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-45deg, #c6f8ff 0%, #595cff 100%);
    transform: translate3d(0px, 20px, 0) scale(0.95);
    filter: blur(20px);
    opacity: var(0.7);
    transition: opacity 0.3s;
    border-radius: inherit;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.box::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
}
</style>
  