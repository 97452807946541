<template>
	<div>
		<h3 class="text-l font-semibold mb-4 mt-auto">{{ title }}</h3>
		<div v-if="data.labels.length != 0" class="flex flex-col justify-center items-center text-center pt-2">
			<Pie :data="chartData" :options="options" />
		</div>
		<div v-if="data.labels.length == 0" class="w-full">
			<div class="pt-2 text-center">
				<div class="mx-auto">
					<img class="w-1/2" src="../../assets/advertencia.gif" alt="No hay datos para mostrar">
					<p class="font-bold py-4">No hay datos para mostrar</p>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
	name: 'PieChart',
	components: {
		Pie
	},
	props: {
		data: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			required: true
		}
	},
	data() {
		return {

			options: {
				responsive: false,
				maintainAspectRatio: true
			}
		}
	},
	computed: {
		chartData() {
			return {
				labels: this.data.labels,
				datasets: [
					{
						backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#FFD700', '#9370DB'],
						data: this.data.data
					}
				]
			}
		}

	}
}
</script>

<style scoped>
img {
	display: inline !important;
	/* width: 20% !important; */
}
</style>
  